import React, { useEffect, useMemo, useRef, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DeviceUUID } from "device-uuid";
import { useSwipeable, config } from "react-swipeable";
import { Motion, spring } from "react-motion";
import IntersectionVisible from "react-intersection-visible";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "@mui/material";
import { Element } from "react-scroll";
import ImageTag from "../Components/ImageTag";
import { actions } from "../../Redux";
import { decode } from "../../Utils/Decrypt";
import Collapse from "@mui/material/Collapse";
import { Colors } from "../../config/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IsVisible from "react-is-visible";
import {
  checkArray,
  checkItemAvailable,
  checkSubCatAvailable,
  checkVoucherAvailable,
  deepCopy,
  getServerImgUrl,
  pluckArray,
  checkNull
} from "../../helpers";

import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import PopupModal from "../Components/PopupModal";
import {
  emptyEditedItemDetail,
  getSelectedItemDetail,
} from "../../Redux/actions";
import { useCallback } from "react";
import cartImg from "../../Assets/images/cartImg.svg";
import bellImg from "../../Assets/images/bellImg.svg";
import filter from "../../Assets/images/filter.svg";
import { StyledSVG } from "../../Utils/style";
import moment from "moment";
import Cart from "./Cart";
import AddItem from "./AddItem";
import AddBundleItem from "./AddBundleItem";
const Home = (params) => {
  // toolbar vh
  const [innerWindowHeight, setInnerWindowHeight] = useState(
    window.innerHeight
  );
  const [isLaptopScreen, setIsLaptopScreen] = useState(false);
  const [isStickyHeader, setIsStickyHeader] = useState(false);

  const [showAddItemPopUp, setShowAddItemPopUp] = useState(false);
  const [showBundleItemPopUp, setShowBundleItemPopUp] = useState(false);
  const [changePropAddressModal, setChangePropAddressModal] = useState(false);

  const closeChangePropAddressModal = useCallback((value) => {
    setChangePropAddressModal(value);
  }, []);

  const toggleAddItemPopUp = useCallback((value = false) => {
    setShowAddItemPopUp(value);
  }, []);
  const toggleBundleItemPopUp = useCallback((value = false) => {
    setShowBundleItemPopUp(value);
  }, []);

  const calcToolbarStatus = () => {
    const outerHeight = window.outerHeight;
    const innerHeight = window.innerHeight;
    const diff = outerHeight - innerHeight;
    console.log(innerHeight, outerHeight, diff, "aaaaaaaaaaaaaaaaaaaaaa");
    if (diff > 120) return true;
    else return false;
  };

  useEffect(() => {
    const rootEl = document.getElementById("root");
    console.log(rootEl, "rootEl");
    if (calcToolbarStatus()) rootEl.classList.remove("toolbar-vh");
    else rootEl.classList.add("toolbar-vh");
  }, [innerWindowHeight]);

  const checkWindowWidth = () => {
    if (window.innerWidth > 768) {
      setIsLaptopScreen(true);
      dispatch(actions.getQuotations(test));
    } else {
      setIsLaptopScreen(false);
    }
  };

  useEffect(() => {
    let resizeListner;
    let scrollListner;
    checkWindowWidth();
    resizeListner = window.addEventListener("resize", function (event) {
      setInnerWindowHeight(window.innerHeight);
      checkWindowWidth();
    });

    scrollListner = window.addEventListener("scroll", (event) => {
      console.log("window.scrollY", window.scrollY);
      if (window.scrollY > 120) {
        setIsStickyHeader(true);
      } else {
        setIsStickyHeader(false);
      }
    });

    return () => {
      window.removeEventListener("resize", resizeListner);
      window.removeEventListener("scroll", scrollListner);
    };
  }, []);

  function updateSize() {
    if(window.innerWidth > 768){
      document.body.classList.add("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      if(slideRouteCols?.[0]){
        slideRouteCols?.[0].classList.add("overflow-visible");
      }
      document.body.classList.add("overflow-visible");
    }else{
      document.body.classList.remove("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      if(slideRouteCols?.[0]){
        slideRouteCols?.[0].classList.remove("overflow-visible");
      }
      document.body.classList.remove("overflow-visible");
    }
    // setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    updateSize();
  }, [showAddItemPopUp,showBundleItemPopUp])
  

  //store scroll position
  const menuSections = document.querySelector(".menu-sections");
  useEffect(() => {
    console.log("scrollPosition", scrollPosition);
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    menuSections?.scrollBy(0, scrollPosition);
  }, [menuSections]);

  const { menuItems, outletDetails, search_input, selectedItem, storeDetails } =
    useSelector((state) => state.storeReducer);
  const selectedItemId = selectedItem?.sub_category_id || 0;
  // console.log('====================================');
  // console.log(selectedItemId);
  // console.log('====================================');
  const searchInputContainer = useRef(null);
  const { cartItems, deliveryType } = useSelector((state) => state.cartReducer);

  // pull data from redux for quotation
  const deliveryOptionsReducer = useSelector(
    (state) => state.deliveryOptionsReducer
  );
  const {
    longitude: storeLong,
    latitude: storeLat,
    address: storeAddress,
    address_2: storeAddressTwo,
    city_id: cityId,
    zip_code: zipCode,
  } = outletDetails;
  const dateFormat = "yyyy/MM/DD";
  const currentDate = moment();
  const deliveryLong = deliveryOptionsReducer?.selectedLocation?.longitude;
  const deliveryLat = deliveryOptionsReducer?.selectedLocation?.latitude;
  const deliveryTime = deliveryOptionsReducer?.deliveryTime;
  const deliveryDate = deliveryOptionsReducer?.deliveryDate;
  const selectedLocation = deliveryOptionsReducer?.selectedLocation;
  const deliveryDateTime = `${deliveryDate} ${deliveryTime}`;
  const deliveryAddress =
    deliveryOptionsReducer?.selectedLocation?.street_address +
    " " +
    deliveryOptionsReducer?.selectedLocation?.unit_number +
    " " +
    deliveryOptionsReducer?.selectedLocation?.city +
    " " +
    deliveryOptionsReducer?.selectedLocation?.zip_code;

  const totalItems = cartItems.reduce((acc, curr) => acc + curr.quantity, 0);
  const pickup_buffer_time = parseInt(
    outletDetails?.settings?.pickup_buffer_time ?? 0
  );
  const checkScheduleTime = moment(deliveryDateTime)
    .subtract(pickup_buffer_time, "minutes");
  const currentMechantTime = moment(new Date())
    .utc()
    .utcOffset(storeDetails?.Merchant?.timezone_offset);

  // get quotation data
  const test = {
    data: {
      // scheduleAt: currentMechantTime.isBefore(checkScheduleTime)
      //   ? checkScheduleTime.format().slice(0, -6) +
      //     `${storeDetails?.Merchant?.timezone_offset}`
      //   : "",
      serviceType: "MOTORCYCLE",
      // specialRequests: ["PURCHASE_SERVICE_1"],
      language: "en_SG",
      stops: [
        {
          coordinates: {
            lat: `${storeLat}`,
            lng: `${storeLong}`,
          },
          address: `${storeAddress}${checkNull(storeAddressTwo,false)?" "+storeAddressTwo:""} ${cityId} ${zipCode}`,
        },
        {
          coordinates: {
            lat: `${deliveryLat}`,
            lng: `${deliveryLong}`,
          },
          address: deliveryAddress,
        },
      ],
      item: {
        quantity: `${totalItems}`,
        weight: "LESS_THAN_3KG",
        categories: ["FOOD_DELIVERY"],
        // categories: ["FOOD_DELIVERY", "OFFICE_ITEM"],
        // handlingInstructions: ["KEEP_UPRIGHT", "FRAGILE"],
      },
      isRouteOptimized: true,
      // "cashOnDelivery": {
      //     "amount": "100"
      // }
    },
  };

  if(currentMechantTime.isBefore(checkScheduleTime)){
    test.data.scheduleAt = checkScheduleTime.format().slice(0, -6) +
    `${storeDetails?.Merchant?.timezone_offset}`;
  }

  const arr = window.location.href.split("/");
  const dispatch = useDispatch();

  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";

  useEffect(() => {
    if (menuItems === null) {
      dispatch(
        actions.getMenuItems({
          merchant_id: decode(arr[arr.length - 1]),
          customer_id: 1,
          date: moment(deliveryDate).format("yyyy-MM-DD"),
          time: deliveryTime,
          outlet_id: decode(arr[arr.length - 1]),
          navigate,
        })
      );
      // dispatch(
      //   actions.getOutletDetails({
      //     outlet_id: decode(arr[arr.length - 1]),
      //   })
      // );
    }
  }, []);

  ///
  const filter_tag_detail = null; // remove
  // const SubCategory = menuItems?.MenuItems ?? []; // remove
  const subCategoryLength = SubCategory?.length; // remove

  const [promotionArr, setPromotionArr] = useState([]);
  const [promotionDisplayArr, setPromotionDisplayArr] = useState([]);

  const [selectedSubcat, setSelectedSubcat] = useState(null);
  const [Categories, setCategories] = useState(menuItems?.MenuItems || []);
  const [SubCategory, setSubCategory] = useState(menuItems?.MenuItems || []);
  const [selectedSubcatArr, setSelectedSubcatArr] = useState([]);
  const selectedSubcatArrRef = useRef(selectedSubcatArr);
  selectedSubcatArrRef.current = selectedSubcatArr;

  const [searchText, setSearchText] = useState(search_input);
  const [filters, setFilters] = useState("");
  const isFilterOrSearchApplied =
    (filters && filters !== "") || (search_input && search_input !== "")
      ? true
      : false;

  //header scrolling effect
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  // infinite scroller
  const [hasMore, setHasMore] = useState(false);
  const [sliceHasMore, setSliceHasMore] = useState(3);

  useEffect(() => {
    const subItems = menuItems?.MenuItems ?? [];
    let newCategories = [...subItems];
    let newSubCategories = [...subItems];
    console.log(Categories, "CategoriesCategories");
    console.log("promotionDisplayArr", promotionDisplayArr);
    if (
      checkArray(promotionDisplayArr) &&
      Categories.findIndex((p) => p.name === "Promotions") === -1
    ) {
      newCategories = [{ id: "1", name: "Promotions" }, ...Categories];
    }
    if (search_input !== "") {
      newCategories = newCategories.filter((SubCat) =>
        filterSubCatTableDetails(SubCat)
      );
      newSubCategories = newSubCategories.filter((SubCat) =>
        filterSubCatTableDetails(SubCat)
      );
    }
    setCategories(newCategories);
    setSubCategory(newSubCategories);
  }, [menuItems, promotionArr, promotionDisplayArr, search_input]);

  function checkItemHasTag(item) {
    let res = filters && filters !== "" ? false : true;
    const filterArr = filters && filters !== "" ? filters.split(",") : [];
    const applyFilterArr =
      filterArr.length > 0 && filter_tag_detail && filter_tag_detail !== ""
        ? filter_tag_detail.filter((item) =>
            filterArr.includes(item.id.toString())
          )
        : [];
    const filterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => !p.name.includes("Contains"))
            .map((filter) => {
              return filter.symbol;
            })
        : [];
    const containfilterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => p.name.includes("Contains"))
            .map((filter) => {
              return filter.symbol;
            })
        : [];
    if (
      containfilterSymbols.length > 0 &&
      filterSymbols.length == 0 &&
      item?.allergy_tags?.length == 0
    ) {
      res = true;
    } else if (res == false && item?.allergy_tags?.length > 0) {
      const allergies = item.allergy_tags;
      for (let i = 0; i < allergies.length; i++) {
        const element = allergies[i];
        if (
          containfilterSymbols.length > 0 &&
          containfilterSymbols.includes(element.toString())
        ) {
          res = false;
          break;
        } else if (
          filterSymbols.includes(element.toString()) ||
          (filterSymbols.length == 0 &&
            containfilterSymbols.length > 0 &&
            !containfilterSymbols.includes(element.toString()))
        ) {
          res = true;
          if (containfilterSymbols.length == 0) break;
        }
      }
    }
    return res;
  }

  function checkItemHasMatchingModifiers(item) {
    let res = true;
    if (item && search_input && search_input !== "") {
      res = false;
      const searchInputText = search_input.toLowerCase();
      if (item?.menu_item_name.toLowerCase().indexOf(searchInputText) > -1) {
        res = true;
      } else if (
        item?.PosMenuitemsModifier &&
        item?.PosMenuitemsModifier.length > 0 &&
        item?.PosMenuitemsModifier.findIndex(
          (p) =>
            p?.Recipe?.recipe_name?.toLowerCase().indexOf(searchInputText) >
              -1 ||
            p?.Ingredient?.ingredient_name
              ?.toLowerCase()
              .indexOf(searchInputText) > -1
        ) > -1
      ) {
        res = true;
      } else if (
        // description
        item?.description &&
        item?.description.toLowerCase().indexOf(searchInputText) > -1
      ) {
        res = true;
      }
    }
    return res;
  }

  function checkItemHasMatch(subCat, item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.menu_item_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function checkPromoItemHasMatch(item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.menu_item_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        "promotions".indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function filterSubCatTableDetails(subCat) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant &&
      SubCategory &&
      SubCategory.length > 0 &&
      subCat?.MenuItem
    ) {
      return (
        subCat?.MenuItem.findIndex((p) => checkItemHasMatch(subCat, p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }
  function filterPromoCatTableDetails() {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END

      return (
        promotionArr.findIndex((p) => checkPromoItemHasMatch(p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          "promotion".toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }

  function filterItemTableDetails(subCat, item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant &&
      SubCategory &&
      SubCategory.length > 0
    ) {
      return checkItemHasMatch(subCat, item);
    }
    return true;
  }

  function filterPromoItemTableDetails(item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END
      return checkPromoItemHasMatch(item);
    }
    return true;
  }

  const makePromotionItem = (promotion) => {
    let copyPromotionArr = [];
    const isValidVoucher = checkVoucherAvailable(
      promotion,
      menuItems?.Merchant?.timezone_offset ?? "00:00",
      deliveryDateTime
    );
    // console.log("menuItemsPromotions2222", promotion?.MenuItem?.length, isValidVoucher)
    if (promotion?.MenuItem?.length > 0 && isValidVoucher) {
      promotion.MenuItem.map((itemKey, promoItemIndex) => {
        const promoSubKey = menuItems?.MenuItems?.findIndex((p) => {
          let promRes = false;
          const subIdCheck =
            itemKey?.categorisation == "SubCategory"
              ? itemKey?.sub_category_id
              : itemKey?.sub_class_id;
          if (p.id == subIdCheck) {
            promRes = true;
          }
          return promRes;
        });
        // const promoSubKey = menuItems?.MenuItems?.findIndex(
        //   (p) => p.id == itemKey?.sub_category_id
        // );
        const promoItemKey = menuItems?.MenuItems?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.id);
        if (
          promoSubKey > -1 &&
          checkSubCatAvailable(
            menuItems?.MenuItems?.[promoSubKey],
            menuItems,
            deliveryDateTime
          ) &&
          menuItems?.MenuItems?.[promoSubKey]?.MenuItem?.[promoItemKey]
        ) {
          const promoItem = deepCopy(
            menuItems?.MenuItems?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          const acutalPrice = parseFloat(
            promoItem?.menu_price?.item_price ?? 0
          );
          let promoPrice = 0;
          let discountPrice = 0;
          if (promotion.type == "Amount") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice =
              acutalPrice - discountPrice >= 0
                ? acutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Percentage") {
            discountPrice = (acutalPrice * parseFloat(promotion.amount)) / 100;
            promoPrice =
              acutalPrice - discountPrice >= 0
                ? acutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice = discountPrice;
          }
          if (promoItem.menu_price) {
            promoItem.menu_price.item_price = promoPrice;
          }
          promoItem.promotions = {
            id: promotion.id,
            type: promotion.type,
            code: promotion.code,
            name: promotion.promotion_name,
            auto_apply: promotion.auto_apply,
            acutalPrice: acutalPrice,
            promotionTotaldiscount: discountPrice,
            amount: promotion.amount,
            discountPerItem: parseFloat(discountPrice),
            combo_allowed: promotion.combo_allowed,
            isLoyaltyVoucher:
              promotion?.loyalty_only_promotion == "1" ? "yes" : "no",
            voucherName: "",
          };
          copyPromotionArr.push(promoItem);
        }
      });
    }
    return copyPromotionArr;
  };

  const increaseSubItems = (add) => {
    setSliceHasMore(add);
    // setScrollerItems(SubCategory?.slice(0, add));
  };

  const fetchMoreData = () => {
    // if (scrollerItems?.length === subCategoryLength) {
    //   setHasMore(false);
    //   return;
    // }
    increaseSubItems(sliceHasMore + 3);
  };

  // for no of cart items
  const getItemTotalQuantity = (item, type) => {
    let res = 0;
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    if (cartItemKey !== -1) {
      res = cartItems
        .filter((cartItem) => cartItem.id == item.id)
        .reduce(function (sum, current) {
          return sum + parseInt(current.quantity);
        }, 0);
    }
    return res > 0 ? (
      <span
        className="txt-pink"
        style={{ color: outletDetails?.settings?.brand_highlight_color }}
      >
        x{res}
      </span>
    ) : null;
  };

  // bottom total bar
  function getBasketTotalQuantity() {
    let qty = 0;
    cartItems.forEach((each) => {
      qty += each.quantity;
    });
    return (
      <div
        className="items-count"
        style={{ backgroundColor: outletDetails?.settings?.brand_main_color }}
      >
        {" "}
        {qty}{" "}
      </div>
    );
  }

  // bottom total bar
  function getBasketTotalAmount() {
    let total = 0;
    cartItems.forEach((each) => {
      total += each.amount * each.quantity;
    });

    return (
      <>
        <small>{`${currencySymbol ?? ""}`}</small>
        {total.toFixed(2)}
      </>
    );
  }

  // show popularity tags
  function getItemTags(item) {
    let res = null;
    if (item?.is_popular && item?.is_popular === 1) {
      res = <span className="tag">Popular</span>;
    } else if (item?.is_trending && item?.is_trending === 1) {
      res = <span className="tag blue">Trending</span>;
    }
    return res;
  }

  // show
  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { backgroundColor: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li style={style} className={className} key={index}>
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (!isLaptopScreen) {
        if (
          hideHeader == true &&
          eventData.dir == "Down" &&
          isHeaderAllUp === true
        ) {
          setHideHeader(false);
        }
        if (hideHeader == false && eventData.dir == "Up") {
          setHideHeader(true);
        }
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    sessionStorage.setItem("scrollPosition", headerPageOffset);
    if (!isLaptopScreen) {
      if (
        headerPageOffset &&
        headerPageOffset > 50 &&
        headerPageScrollHeight > headerPageClientHeight - 168 &&
        hideHeader === false
      ) {
        setHideHeader(true);
      } else if (headerPageOffset === 0 && hideHeader === true) {
        setHideHeader(false);
      }
      if (headerPageOffset === 0 && hideHeader === true) {
        setIsHeaderAllUp(true);
      }
      if (headerPageOffset > 0 && isHeaderAllUp === true) {
        setIsHeaderAllUp(false);
      }
    }
  }

  // const history = useHistory();
  const navigate = useNavigate();
  const deviceid = new DeviceUUID().get();

  useEffect(() => {
    document.body.classList.remove("white");
  }, []);
  useEffect(() => {
    if (SubCategory?.length > 0) {
      increaseSubItems(3);
    }
  }, [SubCategory]);

  useEffect(() => {
    getPromotionItemDetails();
    // makeReorderItems();
  }, [menuItems]);

  useEffect(() => {
    let newPromoDisplayArr = [];
    if (
      checkArray(promotionArr) &&
      menuItems?.PromotionalItems !== null &&
      checkArray(Object.keys(menuItems?.PromotionalItems))
    ) {
      const copyPromotionArr = deepCopy(promotionArr);
      Object.keys(menuItems?.PromotionalItems).map((promoInx) => {
        const promoMenuIds = menuItems?.PromotionalItems[promoInx];
        if (checkArray(promoMenuIds)) {
          promoMenuIds.map((promoMenuId) => {
            const promomenuIndex = copyPromotionArr.findIndex(
              (p) => p.id == promoMenuId && p.promotions.id == promoInx
            );
            if (promomenuIndex > -1) {
              const promoItem = copyPromotionArr[promomenuIndex];
              if (
                filterPromoItemTableDetails(promoItem) &&
                checkItemAvailable(
                  promoItem,
                  menuItems?.Merchant?.timezone_offset ?? "00:00",
                  menuItems,
                  deliveryDateTime
                )
              ) {
                newPromoDisplayArr.push(promoItem);
              }
            }
          });
        }
      });
    } else {
      const copyPromotionArr = deepCopy(promotionArr);
      newPromoDisplayArr = copyPromotionArr.filter(
        (promoItem) =>
          filterPromoItemTableDetails(promoItem) &&
          checkItemAvailable(
            promoItem,
            menuItems?.Merchant?.timezone_offset ?? "00:00",
            menuItems,
            deliveryDateTime
          )
      );
    }
    setPromotionDisplayArr(newPromoDisplayArr);
  }, [promotionArr]);

  useEffect(() => {
    if (selectedSubcatArrRef.current) {
      let targetSub = selectedSubcatArrRef.current.slice(0, 1).shift();
      if (
        subCategoryLength > 2 &&
        selectedSubcatArrRef.current.includes(SubCategory[0].id)
      ) {
        targetSub = SubCategory[0].id;
      } else if (
        subCategoryLength > 2 &&
        selectedSubcatArrRef.current.includes(
          SubCategory[subCategoryLength - 1].id
        )
      ) {
        targetSub = SubCategory[subCategoryLength - 1].id;
      }
      console.log("targetSub", targetSub);
      if (isLaptopScreen && document.getElementById(`head_sub_${targetSub}`)) {
        /* issue with scrolling */
        // setSelectedSubcat(targetSub);
        // document.querySelector(`#head_sub_${targetSub}`).scrollIntoView();
      } else if (
        !isLaptopScreen &&
        document.getElementById(`head_sub_${targetSub}`)
      ) {
        setSelectedSubcat(targetSub);
        document.getElementById(`head_sub_${targetSub}`).scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [selectedSubcatArr]);

  const scrollToSubDiv = (subId, additionHeight = 185) => {
    const targetEl = document.getElementById(`sub_${subId}`);
    console.log("testtttasdvasd", targetEl.offsetTop, additionHeight);
    const targetDiv = document.getElementById("home-wrapper");
    window.scrollTo({
      left: 0,
      top: targetEl.offsetTop + additionHeight,
      behavior: "smooth",
    });
  };

  const subCatClickHandler = (subId, clicked = false) => {
    const subIndex = SubCategory.findIndex((p) => p.id == subId);

    if (subIndex > -1 && sliceHasMore < subIndex) {
      increaseSubItems(subIndex + 2);
    }
    console.log("====================================");
    console.log("i got clicked", subId);
    console.log("====================================");
    setSelectedSubcat(subId);
    document.querySelector(`#head_sub_${subId}`).scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
    setTimeout(() => {
      if (document.getElementById(`sub_${subId}`)) {
        if (isLaptopScreen) {
          scrollToSubDiv(subId);
        } else {
          document.getElementById(`sub_${subId}`).scrollIntoView({
            behavior: "auto",
            block: "start",
          });
        }
      }
    }, 300);
  };
  const getPromotionItemDetails = () => {
    let copyPromotionArr = [];
    if (
      menuItems?.Promotions &&
      menuItems?.Promotions.filter(
        (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
      ).length > 0
    ) {
      const copyPromotions = deepCopy(
        menuItems?.Promotions.filter(
          (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
        )
      );

      copyPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    // if (user_detail?.promotions && user_detail?.promotions.length > 0) {
    //   const copyCopounPromotions = deepCopy(user_detail?.promotions);
    //   copyCopounPromotions.map((promotion, promoKey) => {
    //     const newProArr = makePromotionItem(promotion);
    //     if (newProArr.length > 0) {
    //       copyPromotionArr = [...copyPromotionArr, ...newProArr];
    //     }
    //   });
    // }
    setPromotionArr(copyPromotionArr);
  };

  function getItemDiv(item, itemKey, type) {
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      const checkAutoPay =
        promotionArr.length > 0 && item?.promotions?.id === undefined
          ? promotionArr.findIndex(
              (p) => p.id == item.id && p?.promotions?.auto_apply == "Yes"
            )
          : -1;
      if (checkAutoPay > -1) {
        item = promotionArr[checkAutoPay];
      }
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    const isPromotion = item?.promotions?.id ? true : false;

    const checkAvailable =
      item?.is_86_item == "false" ||
      currentDate.format(dateFormat) != moment(deliveryDate).format(dateFormat)
        ? true
        : false;
    const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);
    return (
      <div
        className={`main-box-new menu-box br-1${isLaptopScreen ? " col-6" : ""}${
          cartItemKey !== -1 ? " active" : ""
        }${checkAvailable === false ? " disabled" : ""}`}
        key={itemKey}
        id={`element_${
          item?.id !== undefined ? "item_id_" + item?.id : "null"
        }`}
      >
        {cartItemKey !== -1 ? (
          <div
            className="side-bar"
            style={{
              backgroundColor: outletDetails?.settings?.brand_main_color,
            }}
          />
        ) : null}
        <div
          onClick={() => {
            if (checkAvailable) {
              getSelectedItemDetail(item);
              emptyEditedItemDetail();
              console.log("====================================");
              console.log("fhejefhjefb");
              console.log("====================================");

              dispatch(
                actions.selectedItem({
                  selectedItem: item,
                })
              );
              dispatch(actions.slideDirection("vertical-slide"));
              const menuType = item.menu_item_type;
              if (isLaptopScreen) {
                if (menuType === "bundled") {
                  toggleBundleItemPopUp(true);
                } else {
                  toggleAddItemPopUp(true);
                }
              } else {
                navigate(
                  `/${menuType === "bundled" ? "addBundleItem" : "addItem"}/${
                    arr[arr.length - 1]
                  }`
                );
              }
            }
          }}
          className="row mx-0 align-items-center"
          style={{
            border:
              isLaptopScreen && cartItemKey !== -1
                ? `1px solid ${outletDetails?.settings?.brand_main_color}`
                : isLaptopScreen
                ? "1px solid #DDDDDD"
                : "none",
          }}
        >
          {checkValidImg && (
            <figure className="col-3 menu-img">
              <ImageTag
                src={getServerImgUrl(item?.image)}
                defaultimage={menuItems?.Merchant?.logo}
                removeImage={true}
                removecol={true}
                replacecolname={"col-12"}
                removecolname={"col-7"}
              />
            </figure>
          )}

          <div
            className={`${
              checkValidImg ? "col-9" : "col-12 px-0"
            }  menu-content`}
          >
            <h4>
              {getItemTotalQuantity(item, type)} {item?.menu_item_name}
            </h4>
            <p> {item?.description}</p>
            <div className="label_row">
              <p className={`pricing ${isPromotion ? "strike-off" : ""}`}>
                {" "}
                <small>{`${currencySymbol ?? ""}`}</small>
                {isPromotion
                  ? item?.promotions?.acutalPrice
                    ? parseFloat(item?.promotions?.acutalPrice).toFixed(2)
                    : "0.00"
                  : item?.menu_price?.item_price
                  ? parseFloat(item?.menu_price?.item_price).toFixed(2)
                  : "0.00"}
              </p>
              {isPromotion && (
                <p
                  className="ml-2 promotion-price"
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                >
                  <small>{`${currencySymbol ?? ""}`}</small>
                  {item?.menu_price?.item_price
                    ? parseFloat(item?.menu_price?.item_price).toFixed(2)
                    : "0.00"}
                </p>
              )}
              {getItemFilterTags(item)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getMenuCon = (subCat) => {
    return subCat?.MenuItem?.filter(
      (item) =>
        filterItemTableDetails(subCat, item) &&
        checkItemAvailable(
          item,
          menuItems?.Merchant?.timezone_offset ?? "00:00",
          menuItems,
          deliveryDateTime
        )
    ).map((item, itemIndex) => {
      const _index = promotionArr.findIndex((el) => el.id === item.id);
      const isPromotion = _index !== -1;
      let updatedItem = isPromotion ? promotionArr[_index] : item;
      // item?.promotions?.id ? true : false;
      const checkAvailable =
        updatedItem?.is_86_item == "false" ||
        currentDate.format(dateFormat) !=
          moment(deliveryDate).format(dateFormat)
          ? true
          : false;
      const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(updatedItem?.image);
      let cartItemKey =
        cartItems && cartItems.length > 0 && updatedItem?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == updatedItem.id &&
                  p.promotions?.id == updatedItem.promotions?.id
                : p.id == updatedItem.id
            )
          : -1;
      return (
        <IsVisible once>
          {(isVisible) => (
            <div
              onClick={() => {}}
              className={`main-box-new menu-box${
                isLaptopScreen ? " col-6" : ""
              }${cartItemKey !== -1 ? " active" : ""}${
                checkAvailable === false ? " disabled" : ""
              }`}
              key={updatedItem?.id}
            >
              {cartItemKey !== -1 ? (
                <div
                  className="side-bar"
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  }}
                />
              ) : null}
              {getItemTags(updatedItem)}
              <div
                onClick={() => {
                  if (checkAvailable) {
                    console.log("====================================");
                    console.log("i am hereee");
                    dispatch(actions.slideDirection("vertical-slide"));
                    console.log("====================================");
                    dispatch(
                      actions.selectedItem({
                        selectedItem: updatedItem,
                      })
                    );
                    console.log(item, "kkkkk");
                    const menuType = item.menu_item_type;
                    if (isLaptopScreen) {
                      if (menuType === "bundled") {
                        toggleBundleItemPopUp(true);
                      } else {
                        toggleAddItemPopUp(true);
                      }
                    } else {
                      navigate(
                        `/${
                          menuType === "bundled" ? "addBundleItem" : "addItem"
                        }/${arr[arr.length - 1]}`
                      );
                    }
                  }
                }}
                className="row mx-0 align-items-center"
                style={{
                  border:
                    isLaptopScreen && cartItemKey !== -1
                      ? `1px solid ${outletDetails?.settings?.brand_main_color}`
                      : isLaptopScreen
                      ? "1px solid #DDDDDD"
                      : "none",
                }}
              >
                {checkValidImg && isVisible && (
                  <figure className="col-3 menu-img">
                    <ImageTag
                      src={getServerImgUrl(updatedItem?.image)}
                      defaultimage={outletDetails?.logo}
                      removeImage={true}
                      removeCol={true}
                      replacecolname={"col-12"}
                      removecolname={"col-9"}
                    />
                  </figure>
                )}
                <div
                  className={`${
                    checkValidImg ? "col-9" : "col-12 px-0"
                  }  menu-content`}
                >
                  <h4>
                    {getItemTotalQuantity(updatedItem, "")}{" "}
                    {updatedItem?.menu_item_name}
                  </h4>
                  <p> {item?.description}</p>
                  <div className="label_row">
                    <p className={`pricing ${isPromotion ? "strike-off" : ""}`}>
                      {" "}
                      <small>{`${currencySymbol ?? ""}`}</small>
                      {isPromotion
                        ? updatedItem?.promotions?.acutalPrice
                          ? parseFloat(
                              updatedItem?.promotions?.acutalPrice
                            ).toFixed(2)
                          : "0.00"
                        : updatedItem?.menu_price?.item_price
                        ? parseFloat(
                            updatedItem?.menu_price?.item_price
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    {isPromotion && (
                      <p
                        className="ml-2 promotion-price"
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                      >
                        <small>{`${currencySymbol ?? ""}`}</small>
                        {updatedItem?.menu_price?.item_price
                          ? parseFloat(
                              updatedItem?.menu_price?.item_price
                            ).toFixed(2)
                          : "0.00"}
                      </p>
                    )}
                    {getItemFilterTags(updatedItem)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </IsVisible>
      );
    });
  };

  const HorizontalScroller = useCallback(() => {
    let horizonTabs = Categories?.filter(
      (SubCat) =>
        checkSubCatAvailable(SubCat, menuItems, deliveryDateTime) ||
        SubCat?.name === "Promotions"
    ).map((subCat, subCatIndex) => {
      const selected = selectedSubcat == subCat?.id;
      console.log("selectedSubcattttt", selectedSubcat);
      return (
        <div
          className={`col link-box ${
            selected || (subCatIndex === 0 && selectedSubcat === null)
              ? "active"
              : ""
          }${subCat?.name === "Promotions"?" promo-text":""}`}id={`head_sub_${subCat?.id}`}
          key={subCat?.id}
        >
          <Motion
            defaultStyle={{ h: 65 }}
            style={{
              h: spring(hideHeader === false ? 65 : 35, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ h }) => {
              const backStyle =
                subCat?.images && !isLaptopScreen
                  ? `url(${getServerImgUrl(
                      subCat?.images
                    )})  no-repeat center center / cover`
                  : outletDetails?.settings?.brand_main_color;
              const backClass =
                subCat?.images && !isLaptopScreen ? "overlay-show" : "";
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    subCatClickHandler(subCat?.id, true);
                    // setSelectedSubcatArr([subCat?.id]);
                  }}
                  className={`blue ${backClass + "test"}`}
                  style={{
                    height: `${h}px`,
                    backgroundImage: backStyle,
                    backgroundColor:
                      !hideHeader && !isLaptopScreen
                        ? outletDetails?.settings?.brand_main_color
                        : selected ||
                          (subCatIndex === 0 && selectedSubcat === null)
                        ? outletDetails?.settings?.brand_main_color
                        : isLaptopScreen
                        ? "transparent"
                        : "#fff",
                  }}
                >
                  <span
                    style={{
                      color:
                        hideHeader || isLaptopScreen
                          ? selected ||
                            (subCatIndex === 0 && selectedSubcat === null)
                            ? "#fff"
                            : outletDetails?.settings?.brand_main_color
                          : "#fff",
                    }}
                  >
                    {subCat?.name}
                  </span>
                </a>
              );
            }}
          </Motion>
        </div>
      );
    });
    return horizonTabs;
  }, [
    Categories,
    selectedSubcat,
    hideHeader,
    promotionDisplayArr,
    outletDetails?.settings?.brand_main_color,
  ]);

  const VerticallScroller = useCallback(
    () =>
      SubCategory.filter((SubCat) =>
        checkSubCatAvailable(SubCat, menuItems, deliveryDateTime)
      ).map((subCat, subIndex) => {
        return (
          <>
            <IntersectionVisible
              onShow={(e) => {
                if (!selectedSubcatArr.includes(subCat?.id)) {
                  setSelectedSubcatArr([...selectedSubcatArr, subCat?.id]);
                }
              }}
              onHide={(e) => {
                setSelectedSubcatArr(
                  selectedSubcatArr.filter((p) => p !== subCat?.id)
                );
              }}
            >
              <h3
                className={`title ${(subCat?.name).replaceAll(" ", "")}`}
                id={`sub_${subCat?.id}`}
                key={subCat?.id}
              >
                {" "}
                {subCat?.name}
                {subCat?.description && subCat?.description !== "" && (
                  <p className="bold mb-0 desSec">
                    {subCat?.description ?? ""}
                  </p>
                )}
              </h3>
            </IntersectionVisible>
            {isLaptopScreen ? (
              <div className="row plr-15">{getMenuCon(subCat)}</div>
            ) : (
              getMenuCon(subCat)
            )}
          </>
        );
      }),
    [
      SubCategory,
      arr,
      cartItems,
      currency,
      dispatch,
      getItemTotalQuantity,
      navigate,
      outletDetails?.settings?.brand_highlight_color,
      outletDetails?.logo,
      promotionArr,
      selectedSubcatArr,
    ]
  );
  const [menuState, setMenuState] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleScroll(div, categoryId) {
    div = div.replaceAll(" ", "");
    const parentDiv = document.querySelector(".menu-row");
    const targetDiv = document.getElementById(`sub_${categoryId}`);
    if (isLaptopScreen) {
      setTimeout(() => {
        scrollToSubDiv(categoryId);
      }, 300);
    } else {
      const diff =
        targetDiv.getBoundingClientRect().top -
        parentDiv.getBoundingClientRect().bottom;

      document.querySelector(".menu-sections").scrollBy(0, diff);
      console.log(
        selectedSubcat,
        selectedSubcatArr,
        "aaa",
        document.querySelector(`#head_sub_${categoryId}`)
      );

      setSelectedSubcat(categoryId);
      document.querySelector(`#head_sub_${categoryId}`).scrollIntoView();
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuSection = () => {
    let res = (
      <div
        className={`menu-sections special-promotion`}
        id="menu-sections"
        onScroll={scrolledElement}
        {...handlers}
      >
        {checkArray(promotionDisplayArr) && filterPromoCatTableDetails() && (
          <Element
            name={"promotion_section"}
            className={`plr-150 ${isLaptopScreen ? "row plr-15" : ""}`}
          >
            <div
              className={`${
                isLaptopScreen ? "main-box-new promotion-heading " : ""
              }menu-box special-pr-0${isLaptopScreen ? " col-12 " : ""}`}
              id={"element_item_id_1"}
            >
              <h3 className="title promotions" id="sub_1">
                Promotions
              </h3>
            </div>

            {promotionDisplayArr.map(function (item, itemKey) {
              return getItemDiv(item, itemKey, "promotion");
            })}
          </Element>
        )}
        <InfiniteScroll
          dataLength={subCategoryLength || 0}
          next={() => fetchMoreData()}
          hasMore={hasMore}
          endMessage={
            <p style={{ textAlign: "center", margin: "10px 0px 30px" }}>
              {subCategoryLength ? <b></b> : null}
            </p>
          }
          scrollableTarget="menu-sections"
        >
          {SubCategory ? VerticallScroller() : null}
        </InfiniteScroll>
      </div>
    );
    return res;
  };

  return outletDetails?.settings?.brand_main_color ? (
    <>
      <div
        className={`wrapper custom_pb home-screen${
          hideHeader ? " scroll_active" : ""
        }`}
        id="home-wrapper"
      >
        <Collapse in={!hideHeader}>
          <header>
            <div className="container">
              <div className="row mx-0 profile-row align-items-center justify-content-between mb-2 w-100">
                <div
                  className={`${
                    isLaptopScreen ? "col-6 col-xl-7" : "col-11"
                  } row align-items-center`}
                >
                  <div className="circle-img col col-md-1">
                    <figure className="">
                      <img src={outletDetails?.logo} alt="" />
                    </figure>
                  </div>
                  <div className="naming-sec col-9 col-md-9 px-0 lmr-65">
                    <p style={{ fontSize: "16px", color: "grey" }}>
                      WELCOME TO
                    </p>
                    <h4>{outletDetails?.account_name}</h4>
                  </div>
                </div>
                {isLaptopScreen && (
                  <div className="col-5 col-xl-4">
                    {/* <div className="row justify-content-end align-items-center">
                      <div className="col-xl-1 col-lg-2 col-md-1 d-flex justify-content-lg-center px-0 col-style-1">
                        <div className="float-left">
                          <StyledSVG
                            color={outletDetails?.settings?.brand_main_color}
                            src={bellImg}
                            width="30"
                          />
                        </div>
                      </div>

                      <div className="col-xl-2 col-lg-2 col-md-3 px-0 col-style-flex">
                        <div className="breadcrumb new-bcrumb mb-0 justify-content-xl-end justify-content-md-end justify-content-lg-start px-0">
                          <ul>
                            <li>
                              {deliveryType.key === "delivery"
                                ? "Delivery"
                                : "Pickup"}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-xl-2 col-lg-3 col-md-3 px-0">
                        <div className="breadcrumb new-bcrumb mb-0 px-0 justify-content-lg-end justify-content-xl-start">
                          <ul>
                            <li className="before-border">
                              {moment(deliveryDateTime).format("hh:mma")}
                            </li>
                          </ul>
                        </div>
                      </div>

                      {selectedLocation && (
                        <div className="col-xl-2 col-lg-3 col-md-3 px-0 col-style-4">
                          <div className="breadcrumb new-bcrumb mb-0 px-0 justify-content-xl-end">
                            <ul>
                              <li className="before-border">
                                {selectedLocation?.nickname ?? ""}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}

                      {selectedLocation && (
                        <div className="col-xl-1 col-lg-1 col-md-2 px-0 mt-1">
                          <a
                            onClick={() => closeChangePropAddressModal(true)}
                            className="change-header text-right"
                            style={{
                              color:
                                outletDetails?.settings?.brand_main_color ??
                                "#39B5B4",
                            }}
                          >
                            Change
                          </a>
                        </div>
                      )}
                    </div>
                    */}

                    <div className="row align-items-center justify-content-end">
                      <div className="col-11 col-md-10 col-lg-10 pr-0">
                        <ul className="breadcrumb mb-0 bcrum pr-0 pl-m-0">
                          <li className="breadcrumb-item">
                            <StyledSVG
                              color={outletDetails?.settings?.brand_main_color}
                              src={bellImg}
                              width="30"
                            />
                          </li>
                          <li className="breadcrumb-item Delivery-icon">
                            {deliveryType.key === "delivery"
                              ? "Delivery"
                              : "Pickup"}
                          </li>

                          <li className="breadcrumb-item">
                            {moment(deliveryDateTime).format("hh:mma")}
                          </li>

                          {selectedLocation && (
                            <li className="breadcrumb-item">
                              {selectedLocation?.nickname ?? ""}
                            </li>
                          )}
                        </ul>
                      </div>

                      {selectedLocation && (
                        <div className="col-1 col-md-2 col-lg-1">
                          <a
                            onClick={() => closeChangePropAddressModal(true)}
                            className="change-yellow text-right"
                            style={{
                              color:
                                outletDetails?.settings?.brand_main_color ??
                                "#39B5B4",
                            }}
                          >
                            Change
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div
                  onClick={() => {
                    if (cartItems?.length && !isLaptopScreen) {
                      dispatch(actions.slideDirection("horizontal-slide"));
                      if (
                        deliveryType.key === "delivery" &&
                        outletDetails?.settings?.enable_delivery_partner ===
                          "yes" &&
                        !(
                          outletDetails?.settings
                            ?.preferred_delivery_partner === "0" ||
                          outletDetails?.settings
                            ?.preferred_delivery_partner === null
                        )
                      )
                        dispatch(actions.getQuotations(test));
                      return navigate(`/cart/${arr[arr.length - 1]}`);
                    }
                  }}
                  className="col-1 col-xl-1 col-lg-1 col-md-1 mb-2 position-relative px-0 text-right side-card-width"
                >
                  <StyledSVG
                    color={outletDetails?.settings?.brand_main_color}
                    src={cartImg}
                    width="30"
                  />
                  <div className="position-relative-m-0">
                    <div
                      className="cartDiv"
                      style={{
                        backgroundColor:
                          outletDetails?.settings?.brand_highlight_color,
                      }}
                    >
                      <span className="cartSpan">
                        {" "}
                        {cartItems.reduce(
                          (acc, curr) => (acc += +curr.quantity),
                          0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row mx-0 welcome-sec d-none">
              <div className="circle-img-lg col-3">
                <figure className="">
                  <img src={userImgIcon} alt="" />
                </figure>
              </div>
              <div className="naming-sec col-9">
                <p> Welcome to </p>
                <h3> Harry's Boat Quay </h3>
                <p>
                  {" "}
                  Powered By{" "}
                  <span className="powered_logo">
                    {" "}
                    <img src={poweredLogo1PngIcon} />
                  </span>
                </p>
              </div>
              <div className="ratings-sec">
                <p> Tap to rate, slide for half star </p>
                <div className="ratings">
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                </div>
              </div>
            </div> */}

              <div className="row mx-0">
                <input
                  type="search"
                  className="search-bar input-text"
                  placeholder="Dishes, Ingredients, or Categories"
                  ref={searchInputContainer}
                  onKeyDown={(e) => {
                    const searchVal = e.target.value ?? "";
                    if (e.key === "Enter") {
                      searchInputContainer?.current.blur();
                      dispatch(actions.updateSearchText(searchVal));
                    }
                  }}
                  onChange={(e) => {
                    const searchVal = e.target.value ?? "";
                    if (e?.target?.value === "") {
                      searchInputContainer?.current.blur();
                      dispatch(actions.updateSearchText(""));
                      setSearchText("");
                    } else {
                      setSearchText(searchVal);
                    }
                  }}
                  value={searchText}
                />
              </div>
            </div>
          </header>
        </Collapse>
        <section className="middle-sec">
          <div className={`sticky-head`}>
            <div className="menu-row row mx-0">
              <div className="col-1  filter-class-f position-relative">
                <div className="position-relative">
                  <StyledSVG
                    color={outletDetails?.settings?.brand_main_color}
                    src={filter}
                    width="30"
                  />
                  <div
                    className="filterDiv"
                    style={{
                      backgroundColor:
                        outletDetails?.settings?.brand_highlight_color,
                    }}
                  >
                    <span className="filterSpan">0</span>
                  </div>
                </div>
              </div>
              <div className="col d-flex overflow-auto px-0 mr-2">
                {Categories ? HorizontalScroller() : null}
              </div>
              <div className="menu_sec col-1 px-0 filter-class">
                <div
                  id="basic-button"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <MoreHorizIcon
                    sx={{ color: outletDetails?.settings?.brand_main_color }}
                  />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem>Jump to:</MenuItem>
                  {Categories?.filter((SubCat) =>
                    checkSubCatAvailable(SubCat, menuItems, deliveryDateTime)
                  ).map((category) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          if (isLaptopScreen) {
                            subCatClickHandler(category?.id, true);
                          } else {
                            handleScroll(category.name, category.id);
                          }
                          handleClose();
                        }}
                        sx={{
                          color: {
                            color: outletDetails?.settings?.brand_main_color,
                          },
                        }}
                      >
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </div>
          </div>

          {isLaptopScreen ? (
            <div className={`row mx-0 mt-new`}>
              <div className={`col-xl-9 col-lg-8 col-md-8`}>
                {getMenuSection()}
              </div>
              <div className={`col-xl-3 col-lg-4 col-md-4`}>
                <div className={`width-30 sticky`}>
                  <div className="white-bg">
                    <Cart
                      isLaptopScreen={isLaptopScreen}
                      changePropAddressModal={changePropAddressModal}
                      showAddItemPopUp={showAddItemPopUp}
                      showBundleItemPopUp={showBundleItemPopUp}
                      closeChangePropAddressModal={closeChangePropAddressModal}
                      isStickyHeader={isStickyHeader}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            getMenuSection()
          )}
        </section>

        {!isLaptopScreen && (
          <section
            className={`bottom-sec m-0 ${
              cartItems?.length <= 0 ? "disabled" : ""
            }`}
          >
            <div className="sub-btn my-0 maxBtn">
              <Button
                type="button"
                className="d-flex justify-content-between font-16"
                onClick={() => {
                  if (cartItems?.length) {
                    dispatch(actions.slideDirection("horizontal-slide"));
                    if (
                      deliveryType.key === "delivery" &&
                      outletDetails?.settings?.enable_delivery_partner ===
                        "yes" &&
                      !(
                        outletDetails?.settings?.preferred_delivery_partner ===
                          "0" ||
                        outletDetails?.settings?.preferred_delivery_partner ===
                          null
                      )
                    )
                      dispatch(actions.getQuotations(test));
                    return navigate(`/cart/${arr[arr.length - 1]}`);
                  }
                }}
                style={{
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                  "&:hover": {
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  },
                }}
              >
                <div className="col-3 col-md-2">
                  <div className="items-count float-left">
                    {" "}
                    {getBasketTotalQuantity()}{" "}
                  </div>
                </div>
                <div className="col-5 text-center col-md-8">
                  <strong> View Basket </strong>
                </div>
                <div className="col-4 px-0 col-md-2">
                  <div className="total-price">
                    <strong>{getBasketTotalAmount()}</strong> Total{" "}
                  </div>
                </div>
              </Button>
            </div>
          </section>
        )}
        <PopupModal
          showDrawer={showAddItemPopUp}
          toggleDrawer={toggleAddItemPopUp}
          height={"80%"}
          maxWidth={"500px"}
        >
          <AddItem
            isLaptopScreen={isLaptopScreen}
            toggleAddItemPopUp={toggleAddItemPopUp}
          />
        </PopupModal>
        <PopupModal
          showDrawer={showBundleItemPopUp}
          toggleDrawer={toggleBundleItemPopUp}
          height={"80%"}
          maxWidth={"500px"}
        >
          <AddBundleItem
            isLaptopScreen={isLaptopScreen}
            toggleBundleItemPopUp={toggleBundleItemPopUp}
          />
        </PopupModal>
      </div>
    </>
  ) : (
    ""
  );
};
export default Home;
