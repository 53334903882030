import { toast } from "react-toastify";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { Colors } from "../../config/constants";
import { storeServices } from "../../services";
import { actions } from "../../Redux";
import * as actionTypes from "../actionTypes";

function* getMenuItems(action) {
  yield put({ type: actionTypes.START_LOADING });
  yield put(actions.getOutletDetails({
    outlet_id: action?.payload?.outlet_id,
  }));
  const { data } = yield call(storeServices.getMenuItems, action?.payload);
  if (data?.status) {
    yield put({
      type: actionTypes.GET_MENU_ITEMS_SUCCEEDED,
      payload: {
        menuItems: data?.data,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.GET_MENU_ITEMS_FAILED,
      payload: { error: data?.message },
    });
    console.log(action);
    setTimeout(() => action.payload.navigate(-1), 1500);
  }
  // const { tagData } = yield call(storeServices.getMenuItems, action?.payload);
  // if (tagData?.status) {
  //   yield put({
  //     type: actionTypes.GET_TAGS_SUCCEEDED,
  //     payload: {
  //       filter_tag_detail: tagData?.data,
  //     },
  //   });
  // } else {
  //   toast.error(tagData.message);
  //   yield put({
  //     type: actionTypes.GET_TAGS_FAILED,
  //     payload: { error: tagData?.message },
  //   });
  //   console.log(action);
  //   setTimeout(() => action.payload.navigate(-1), 1500);
  // }
  yield put({ type: actionTypes.STOP_LOADING });
}

function* getOutletDetails(action) {
  const { data } = yield call(storeServices.getOutletDetails, action?.query);
  let defaultColors = yield select(
    (state) => state.storeReducer.storeDetails.Merchant
  );
  console.log("getOutletDetails", data);
  if (data?.status) {
    yield put({
      type: actionTypes.GET_OUTLET_DETAILS_SUCCEEDED,
      payload: {
        outletDetails: {
          ...data?.data,
          settings: {
            ...data?.data?.settings,
            brand_highlight_color:
              data.data.settings.brand_highlight_color ||
              defaultColors.brand_highlight_color ||
              "#d51a66",
            brand_main_color:
              data.data.settings.brand_main_color ||
              defaultColors.brand_main_color ||
              "#3d2ea1",
          },
        },
      },
    });
    console.log("getOutletDetails", data);
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.GET_OUTLET_DETAILS_FAILED,
      payload: { error: data?.message },
    });
  }
}

function* getStoreDetails(action) {
  const { data } = yield call(storeServices.getStoreDetails, action?.query);
  if (data?.status) {
    let payload = data.data;
    console.log(data.data.Merchant.brand_highlight_color, "1234");
    if (!data.data.Merchant.brand_highlight_color) {
      payload.Merchant.brand_highlight_color = "#d51a66";
      payload.Merchant.brand_main_color = "#3d2ea1";
    }
    yield put({
      type: actionTypes.GET_STORE_DETAILS_SUCCEEDED,
      payload: {
        storeDetails: {
          ...payload,
        },
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.GET_STORE_DETAILS_FAILED,
      payload: { error: data?.message },
    });
  }
}

function* storeSaga() {
  yield takeLatest(actionTypes.GET_MENU_ITEMS_REQUESTED, getMenuItems);
  yield takeLatest(actionTypes.GET_STORE_DETAILS_REQUESTED, getStoreDetails);
  yield takeLatest(actionTypes.GET_OUTLET_DETAILS_REQUESTED, getOutletDetails);
}

export default storeSaga;
