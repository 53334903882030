import * as actionTypes from "../actionTypes";

export const addCartItem = ({ item }) => ({
  type: actionTypes.ADD_CART_ITEM,
  item,
});

export const updateCartItem = ({ index, item, isToast = true  }) => ({
  type: actionTypes.UPDATE_CART_ITEM,
  index,
  item,
  isToast,
});

export const removeCartItem = ({ index }) => ({
  type: actionTypes.REMOVE_CART_ITEM,
  index,
});

export const finalCartSave = (data) => ({
  type: actionTypes.FINAL_CART_SAVE,
  payload: { ...data },
});

export const changeOrderStatus = (data) => ({
  type: actionTypes.ORDER_STATUS,
  payload: { ...data },
});

// BY ABDUS
export const updateUserTip = (tip) => ({
  type: actionTypes.UPDATE_USER_TIP,
  payload: +tip,
});
export const updateUserDiscount = (discount) => ({
  type: actionTypes.UPDATE_USER_DISCOUNT,
  payload: +discount,
});

export const editItemQuantity = (quantity) => ({
  type: actionTypes.EDIT_ITEM_QUANTITY,
  payload: quantity,
});

export const replaceCartItem = (data) => ({
  type: actionTypes.REPLACE_CART_ITEM,
  payload: data,
});

export const updateCartBasicDetails = (data) => ({
  type: actionTypes.UPDATE_BASIC_CART_DETAILS,
  payload: data,
});

export const placeOrder = (payload) => ({
  type: actionTypes.PLACE_ORDER_REQUESTED,
  payload,
});

export const getQuotations = (payload) => ({
  type: actionTypes.GET_QUOTATIONS,
  payload,
});
