import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ellipse, ellipseFill, locationIcon } from "../../Assets";
import { useSelector } from "react-redux";
import { StyledSVG } from "../../Utils/style";

const StepperProgress = ({ steps, checkKey = "label", type }) => {
  // finding index of time:null in steps, if not return last index
  console.log(steps, type, "lllllll");
  let activeStepperIndex = 0;
  steps.forEach((step, idx) => {
    if (step.time !== null) activeStepperIndex = idx;
  });

  steps.findIndex((step) => step.time === null);
  if (activeStepperIndex === -1) activeStepperIndex = steps.length;

  console.log(activeStepperIndex, "activeStepperIndex");
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    console.log(activeStepperIndex, "activeStepperIndex");
    setActiveStep(activeStepperIndex);
  }, [activeStepperIndex]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { outletDetails } = useSelector((state) => state.storeReducer);

  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiStepLabel-iconContainer": {
          paddingLeft: "3px",
        },
        "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
          color: "#e91e63",
          width: "0.8em",
          height: "0.8em",
        },
        // "& .last_icon .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
        //   borderRadius: "40px",
        //   fill: "#fff",
        //   border: "1px solid #e91e63",
        // },
        "& .MuiStepLabel-iconContainer .MuiSvgIcon-root .MuiStepIcon-text": {
          display: "none",
        },
        "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
          fontSize: "16px",
          color: "#999",
          fontWeight: "bold",
        },
        "& .MuiStepLabel-labelContainer .MuiTypography-caption": {
          fontSize: "16px",
          color: "#383737",
          fontWeight: "normal",
        },
        "& .MuiStepContent-root .MuiTypography-root": {
          fontSize: "16px",
          color: "#383737",
          fontWeight: "normal",
        },
        "& .MuiStepConnector-line": {
          minHeight: "8px",
        },
        "& .MuiStepConnector-line": {
          minHeight: "8px",
          height: "100%",
          borderLeft: "3px dashed #aeaeae !important",
        },
        // "& .MuiStepConnector-root": {
        //   marginLeft: "6px !important",
        //   top: "3%",
        //   transform: "translate(0, 50%)",
        //   position: "absolute",
        //   height: "100%",
        // },
        "& .MuiStepConnector-root": {
          marginLeft: "6px !important",
          top: "-33%",
          transform: "translate(0, 50%)",
          position: "absolute",
          height: `calc(100% - 42px)`,
        },
        "& .MuiStepLabel-iconContainer": {
          zIndex: 9,
        },
        "& .MuiStepContent-root": {
          border: 0,
        },
      }}
    >
      <Stepper
        activeStep={activeStep}
        expanded={true}
        orientation="vertical"
        nonLinear={true}
      >
        {steps.map((step, index) => {
          console.log(step, "1234");
          return (
            <Step
              active={true}
              key={step[checkKey]}
              onClick={() => setActiveStep(index)}
            >
              <StepLabel
                className={step.time ? "labelColor" : ""}
                sx={{ color: "white", alignItems: "start" }}
                StepIconComponent={(iconProps) => {
                  const { active, completed } = iconProps;
                  const icon =
                    type === "address" ? (
                      <img
                        alt=""
                        src={
                          step?.icon
                            ? step?.icon
                            : step.time
                            ? ellipseFill
                            : ellipse
                        }
                        width={15}
                      />
                    ) : step?.icon ? (
                      step?.icon
                    ) : step.time ? (
                      <span
                        style={{
                          color: outletDetails.settings?.brand_highlight_color,
                          backgroundColor: "white",
                        }}
                        className={"icon-ellipse_fill"}
                        width={15}
                      ></span>
                    ) : (
                      <span
                        style={{
                          color: outletDetails.settings?.brand_highlight_color,
                        }}
                        className={"icon-ellipse"}
                        width={15}
                      ></span>
                    );
                  // <span style={{color: outletDetails.settings?.brand_highlight_color, backgroundColor: "white"}} className={step?.icon ? step?.icon : step.time ? 'icon-ellipse_fill' : 'icon-ellipse'} width={15} ></span>
                  return (
                    <figure ownerState={{ completed, active }}>{icon}</figure>
                  );
                }}
                // className={step?.icon ? "last_icon" : ""}
                optional={
                  step?.caption ? (
                    <Typography color="red" variant="caption">
                      {step.caption}
                    </Typography>
                  ) : null
                }
              >
                {step[checkKey]}
              </StepLabel>
              {step?.caption === undefined && (
                <StepContent
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography>{step.description || step.time}</Typography>
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
export default StepperProgress;
