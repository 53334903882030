import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  logoWhtOrdrr,
  backIcon,
  calendarSmall,
  userConIcon,
  locationBigIcon,
} from "../../Assets";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FloatingInput from "../Components/FloatingInput";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FloatingGroupInput from "../Components/FloatingGroupInput";
import SelectInput from "../Components/SelectInput";

const Delivery = ({ prevTab, nextTab }) => {
  useEffect(() => {
    document.body.classList.add("white");
  }, []);
  const [isUpdate, setIsUpdate] = useState(true);
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { outletDetails, menuItems } = useSelector(
    (state) => state.storeReducer
  );

  const [address, setAddress] = useState({
    nickname: "",
    street_address: "",
    unit_number: "",
    country: 0,
    city: "",
    zip_code: "",
  });

  const onChangeAddress = (type) => (e) => {
    setAddress({
      ...address,
      [type]: e.target.value,
    });
  };

  return (
    <div className="wrapper">
      <section className="login-section OtpVerifyMs row mx-0">
        <div className="top-bar">
          <a
            type="button"
            onClick={() => navigate(-1)}
            className="backlink col-2"
          >
            <figure>
              <img src={backIcon} alt="" />
            </figure>
          </a>

          <div className="right-logo col-6">
            {" "}
            <img src={logoWhtOrdrr} />{" "}
          </div>
        </div>

        <div className="sm-content col-12">
          <figure className="email-what-img">
            <img src={locationBigIcon} alt="" />
          </figure>
          <h2 className="mb-4"> Delivery Details </h2>
          <p>
            Please provide uss your <b>primary address for deliveries</b> related
            to this account.
          </p>
        </div>

        <div className="col-12">
          <form>
            <div className="group">
              <FloatingInput label="STREET ADDRESS" />
            </div>

            <div className="group">
              <FloatingInput label="UNIT NUMBER" />
            </div>

            <div className="group row d-flex">
              <FloatingGroupInput fullWidth variant="standard" className="mt-0">
                <InputLabel>COUNTRY & CITY</InputLabel>
                <div className="row mx-0 mt-16">
                  <div className="col-3 countrySelect selectBox">
                    <SelectInput
                      brand_highlight_color={
                        outletDetails?.settings?.brand_highlight_color
                      }
                      country={true}
                      options={[{ country_code: "SG" }]}
                      value={address.country}
                      keyToCheck="country_code"
                      onChange={onChangeAddress("country")}
                    />
                  </div>
                  <div className="col-9">
                    <Input
                      autoComplete="new-password"
                      fullWidth
                      onChange={onChangeAddress("city")}
                      value={address.city || " "}
                    />
                  </div>
                </div>
              </FloatingGroupInput>
            </div>
            <div className="group">
              <FloatingInput label="ZIP CODE" />
            </div>

            <div className="group sub-btn mb-0 d-flex justify-content-between">
              <Button
                type="button"
                className="col-12 mr-1"
                onClick={() => {
                  if (isUpdate) {
                    navigate("/deliveryOption");
                  } else {
                    setIsUpdate(true);
                  }
                }}
                style={{
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                }}
              >
                NEXT
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
export default Delivery;
