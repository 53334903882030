import {
  DeliveryOptionScreen,
  AddItemScreen,
  AddBundleItemScreen,
  EditItemScreen,
  EditBundleItemScreen,
  HomeScreen,
  CartScreen,
  DeliveryInfoScreen,
  HelpScreen,
  OrderConfirmationScreen,
  PaymentScreen,
  TrackStatusScreen,
} from "../Scenes/Screens";

import {
  EmailScreen,
  LoginScreen,
  OtpVerificationScreen,
  SetPasswordScreen,
  UserDetailsScreen,
} from "../Scenes/AuthScreens";

import UnableToLoadData from "../Scenes/Components/UnableToLoadData";

export const Routing1 = [
  {
    path: "/login",
    component: LoginScreen,
  },
  {
    path: "/email",
    component: EmailScreen,
  },
  {
    path: "/otpVerification",
    component: OtpVerificationScreen,
  },
  {
    path: "/setPassword",
    component: SetPasswordScreen,
  },
  {
    path: "/userDetails",
    component: UserDetailsScreen,
  },
];

export const Routing = [
  {
    path: "/",
    component: <DeliveryOptionScreen />,
  },
  {
    path: "/dashboard/:id",
    component: <HomeScreen />,
  },
  {
    path: "/addItem/:id",
    component: <AddItemScreen />,
  },
  {
    path: "/addBundleItem/:id",
    component: <AddBundleItemScreen />,
  },
  {
    path: "/editItem/:id",
    component: <EditItemScreen />,
  },
  {
    path: "/editBundleItem/:id",
    component: <EditBundleItemScreen />,
  },
  {
    path: "/cart/:id",
    component: <CartScreen />,
  },
  {
    path: "/help",
    component: <HelpScreen />,
  },
  {
    path: "/delivery",
    component: <DeliveryInfoScreen />,
  },
  {
    path: "/payment",
    component: <PaymentScreen />,
  },
  {
    path: "/trackStatus/:id",
    component: <TrackStatusScreen />,
  },
  {
    path: "/confirm",
    component: <OrderConfirmationScreen />,
  },
  {
    path: "not_found",
    component: <UnableToLoadData />,
  },
];

export const Routing2 = [
  {
    path: "/addItem/:id",
    component: <AddItemScreen />,
  },
  {
    path: "/editItem/:id",
    component: <EditItemScreen />,
  },
];
