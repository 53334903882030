import * as actionTypes from '../actionTypes';

export const paymentIntent = (payload) => ({
    type: actionTypes.PAYMENT_INTENT_REQUESTED,
    payload
});

export const paymentToken = (payload) => ({
    type: actionTypes.PAYMENT_TOKEN_REQUESTED,
    payload
});

export const confirmPayment = (payload) => ({
    type: actionTypes.CONFIRM_PAYMENT_REQUESTED,
    payload
});

export const trackOrder = (payload) => ({
    type: actionTypes.TRACK_ORDER_DETAILS_REQUESTED,
    payload
});

export const whereIsMyOrder = (payload) => ({
    type: actionTypes.WHERE_IS_MY_ORDER_REQUESTED,
    payload
});

