import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { paymentServices } from "../../services";
import * as actionTypes from "../actionTypes";

function* paymentIntent(action) {
  const { data } = yield call(paymentServices.paymentIntent, action.payload);
  console.log("paymentIntent", data);
  if (data.status) {
    // toast.success("Payment Successful");
    yield put({
      type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
      payload: {
        paymentIntent: data.data,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.PAYMENT_INTENT_FAILED,
      payload: { error: data.message },
    });
  }
}
function* paymentToken(action) {
  const { data } = yield call(paymentServices.paymentToken, action.payload);
  console.log("paymentToken", data);

  if (data.status) {
    yield put({
      type: actionTypes.PAYMENT_TOKEN_SUCCEEDED,
      payload: {
        paymentTokenData: data,
        paymentTokengenerated: true,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.PAYMENT_TOKEN_FAILED,
      payload: { error: data.message },
    });
  }
}

function* confirmPayment(action) {
  const { data } = yield call(paymentServices.confirmPayment, action.payload);
  console.log("confirmPayment", data);

  if (data.status) {
    toast.success("Payment Successful");
    yield put({
      type: actionTypes.CONFIRM_PAYMENT_REQUESTED,
      payload: {
        confirmPaymentDetails: data,
        paymentTokengenerated: false,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.CONFIRM_PAYMENT_FAILED,
      payload: { error: data.message },
    });
  }
}

function* trackOrder(action) {
  const { data } = yield call(paymentServices.trackOrder, action.payload);
  if (data.status) {
    yield put({
      type: actionTypes.TRACK_ORDER_DETAILS_SUCCEEDED,
      payload: {
        trackOrder: data?.data,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.TRACK_ORDER_DETAILS_FAILED,
      payload: { error: data.message },
    });
  }
}

function* whereIsMyOrder(action) {
  const { data } = yield call(paymentServices.whereIsMyOrder, action.payload);
  if (data.status) {
    console.log("whereIsMyOrder", data);
    yield put({
      type: actionTypes.WHERE_IS_MY_ORDER_SUCCEEDED,
      payload: {
        whereIsMyOrder: { message: data?.message },
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.WHERE_IS_MY_ORDER_FAILED,
      payload: { error: data.message },
    });
  }
}

function* paymentSaga() {
  yield takeLatest(actionTypes.PAYMENT_INTENT_REQUESTED, paymentIntent);
  yield takeLatest(actionTypes.PAYMENT_TOKEN_REQUESTED, paymentToken);
  yield takeLatest(actionTypes.CONFIRM_PAYMENT_REQUESTED, confirmPayment);
  yield takeLatest(actionTypes.TRACK_ORDER_DETAILS_REQUESTED, trackOrder);
  yield takeLatest(actionTypes.WHERE_IS_MY_ORDER_REQUESTED, whereIsMyOrder);
}

export default paymentSaga;
