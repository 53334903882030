import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import StepperProgress from "../Components/StepperProgress";
import { crossIcon, locationBigIcon, locationIcon } from "../../Assets";
import SelectInput from "../Components/SelectInput";
import { Button } from "@mui/material";
import FloatingInput from "../Components/FloatingInput";
import PopupModal from "../Components/PopupModal";
import FloatingGroupInput from "../Components/FloatingGroupInput";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { loadingToggleStatus } from "../../Redux/actions";
import moment from "moment";
import PaymentScreen from "./Payment";
import { actions } from "../../Redux";
import { StyledSVG } from "../../Utils/style";
import { countryList } from "../../Utils/constants";

const DeliveryInfo = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { finalCart, cartBasicDetails } = useSelector(
    (state) => state.cartReducer
  );
  const {
    selectedStore,
    locations,
    selectedLocation,
    deliveryType,
    deliveryDate,
    deliveryTime,
  } = useSelector((state) => state.deliveryOptionsReducer);

  const deliveryDateTime = deliveryDate + " " + deliveryTime;

  const { outletDetails, storeDetails } = useSelector(
    (state) => state.storeReducer
  );
  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";

  const [isLaptopScreen, setIsLaptopScreen] = useState(false);

  const checkWindowWidth = () => {
    if (window.innerWidth > 768) {
      setIsLaptopScreen(true);
    } else {
      setIsLaptopScreen(false);
    }
  };

  useEffect(() => {
    let resizeListner;
    checkWindowWidth();
    resizeListner = window.addEventListener("resize", function (event) {
      checkWindowWidth();
    });

    return () => {
      window.removeEventListener("resize", resizeListner);
    };
  }, []);

  const [options, setOptions] = useState({
    deliveryType: 0,
    deliveryLocation: 0,
    store: 0,
  });

  const [address, setAddress] = useState({
    nickname: "",
    street_address: "",
    unit_number: "",
    country: "",
    city: "",
    zip_code: "",
  });

  useEffect(() => {
    document.body.classList.add("white");
    dispatch(loadingToggleStatus(true));
    setTimeout(() => {
      dispatch(loadingToggleStatus(false));
    }, 1000);
  }, []);

  const onChangeAddress = (type) => (e) => {
    setAddress({
      ...address,
      [type]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isUpdate) {
      setAddress({
        nickname: "",
        street_address: "",
        unit_number: "",
        country: "",
        city: "",
        zip_code: "",
      });
    }
  }, [isUpdate]);

  const onPressAddUpdate = () => {
    dispatch(
      actions.getLatLngFromAddrs({
        ...address,
        country: countryList[address.country]?.country_code,
      })
    );
  };

  const [riderModal, setRiderModal] = useState(false);
  const [riderNote, setRiderNote] = useState("");
  const [finalRiderNote, setFinalRiderNote] = useState("");
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(true);

  const closeRiderModal = useCallback(
    (value, cancel = false) => {
      setRiderModal(value);
      if (cancel) {
        setRiderNote(finalRiderNote);
      }
    },
    [finalRiderNote, riderNote]
  );
  const saveRiderModal = useCallback(() => {
    setRiderModal(false);
    setFinalRiderNote(riderNote);
  }, [finalRiderNote, riderNote]);

  useEffect(() => {
    dispatch(actions.riderNote(finalRiderNote));
  }, [finalRiderNote]);

  const handleChange = (type) => (e) => {
    setOptions({ ...options, [type]: e.target.value });
  };

  const closeChangeAddressModal = useCallback(
    (value) => {
      setChangeAddressModal(value);
    },
    [changeAddressModal]
  );

  //  calculate delivery time
  const { enable_delivery_partner, preferred_delivery_partner } =
    outletDetails?.settings;
  let timeToDeliver = 0;
  if (enable_delivery_partner === "yes") {
    timeToDeliver = outletDetails?.settings?.partner_delivery_estimate;
  } else {
    timeToDeliver = outletDetails?.settings?.own_delivery_estimate;
  }

  let deliveryArr = { 1: "42 Jervois", 2: "43 Jervois" };
  const steps = [
    {
      label: "ORIGINATION",
      description: selectedStore?.nickname,
      caption: (
        <div className="row">
          <div className="col-8 col-lg-8 col-md-12 h6">
            {(selectedStore?.address || "") +
              " " +
              (selectedStore?.address_2 || "")}
          </div>
          <div className="col-8 col-lg-8 col-md-12 h6" style={{ marginTop: "-8px" }}>
            {(selectedStore?.city_id || "") +
              " " +
              (selectedStore?.zip_code || "")}
          </div>
        </div>
      ),
      icon: locationIcon,
    },
    {
      label: "DESTINATION",
      description: selectedLocation?.nickname,
      caption: (
        <div className="row">
          <div className="col-8 h6">
            {selectedLocation?.street_address || ""}
          </div>
          {selectedLocation?.unit_number && (
            <div className="col-8 h6" style={{ marginTop: "-8px" }}>
              {selectedLocation?.unit_number || ""}
            </div>
          )}
          <div className="col-8 h6" style={{ marginTop: "-8px" }}>
            {(selectedLocation?.city || "") +
              " " +
              (selectedLocation?.zip_code || "")}
          </div>

          {/* {selectedLocation ? <div className="col-4 text-align-right">
            <span
              className="anchor_link"
              onClick={() => closeChangeAddressModal(true)}
              style={{ color: outletDetails?.settings?.brand_highlight_color }}
            >
              CHANGE
            </span>
          </div> : null} */}
        </div>
      ),
      icon: locationIcon,
    },
  ];

  const steps1 = [
    {
      label: "ORIGINATION",
      description: selectedStore?.nickname,
      caption: (
        <div className="row">
          <div className="col-8 h6">
            {(selectedStore?.address || "") +
              " " +
              (selectedStore?.address_2 || "")}
          </div>
          <div className="col-8 h6" style={{ marginTop: "-8px" }}>
            {(selectedStore?.city_id || "") +
              " " +
              (selectedStore?.zip_code || "")}
          </div>
          {selectedLocation ? (
            <div className="col-4 text-align-right">
              <span
                className="anchor_link bolder"
                onClick={() => closeChangeAddressModal(true)}
                style={{
                  color: outletDetails?.settings?.brand_highlight_color,
                }}
              >
                CHANGE
              </span>
            </div>
          ) : null}
        </div>
      ),
      icon: locationIcon,
    },
  ];

  const getPaymentCon = () => (
    <div className="Payment">
      <div className="col-12 cd-px-md-4">
        <h1 className="title pl-0">
          {selectedLocation ? "Delivery" : "Pickup"} Info
        </h1>
      </div>
      <div className="col-12 cd-px-md-4">
        <b className="b-size">
          {deliveryType?.key == "delivery"
            ? `Delivery on ${moment(deliveryDateTime).format(
                "Do MMM"
              )} at ${moment(deliveryDateTime).format("h:mm A")}`
            : `Pickup scheduled for  ${moment(deliveryDateTime).format(
                "Do MMM"
              )} at ${moment(deliveryDateTime).format("h:mm A")}`}
        </b>
      </div>
      <div className="col-12 step-master font-size-h6 mt-3 cd-px-md-4">
        <StepperProgress
          type="address"
          steps={selectedLocation ? steps : steps1}
        />
      </div>
      {finalRiderNote !== "" ? (
        <div className="col-12 mb-4">
          <div className="d-flex flex-wrap">
            <div className="col-10 px-0">
              <span className="highlight-text">RIDER NOTE</span>
            </div>
            <div className="col-2 px-0 text-align-right">
              <span
                className="anchor_link bolder"
                onClick={() => closeRiderModal(true)}
                style={{
                  color: outletDetails?.settings?.brand_highlight_color,
                }}
              >
                EDIT
              </span>
            </div>
            <div className="col-10 mt-2 px-0">
              <span>{finalRiderNote}</span>
            </div>
          </div>
        </div>
      ) : deliveryType.key === "delivery" ? (
        <div className="col-12 text-align-left mb-4 mt-2 cd-px-md-4">
          <span
            className="anchor_link bolder"
            onClick={() => closeRiderModal(true)}
            style={{
              color: outletDetails?.settings?.brand_highlight_color,
            }}
          >
            ADD RIDER NOTE
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <>
      <div className="wrapper colorGreyBg wrap-px-0">
        <PopupModal showDrawer={riderModal} toggleDrawer={closeRiderModal}>
          <>
            <div className="rider-note-modal">
              <div className="col-12 cross-btn-con">
                <figure
                  className="float-right"
                  onClick={() => closeRiderModal(false, true)}
                >
                  {/* <img width="20px" src={crossIcon} alt="" /> */}
                  <StyledSVG
                    color={outletDetails?.setings?.brand_highlight_color}
                    src={crossIcon}
                    width="40"
                  />
                </figure>
              </div>
              <div className="col-12 my-0">
                <h1
                  className="title"
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                >
                  Add Rider Note
                </h1>
              </div>
              <div className="col-12 my-3">
                <p className="sm-title px-0">
                  Please provide your note to the rider
                </p>
              </div>
              <div className="col-12 mt-0 mb-3">
                <FloatingInput
                  label="NOTE"
                  value={riderNote}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setRiderNote(e?.target?.value);
                  }}
                  beforeColor="#999"
                  afterColor="#3e38a1"
                  labelColor="#363636"
                />
              </div>
            </div>
            <div className="rider-note-modal-btm">
              <div className="m-3 btm-sec sub-btn m-5">
                <Button
                  type="button"
                  onClick={() => saveRiderModal()}
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                    maxWidth: "100%",
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </>
        </PopupModal>
        <PopupModal
          showDrawer={changeAddressModal}
          toggleDrawer={closeChangeAddressModal}
        >
          <>
            <div className="rider-note-modal">
              <div className="col-12 cross-btn-con">
                <figure
                  className="float-right"
                  onClick={() => closeChangeAddressModal(false)}
                >
                  {/* <img width="20px" src={crossIcon} alt="" /> */}
                  <StyledSVG
                    color={outletDetails?.setings?.brand_highlight_color}
                    src={crossIcon}
                    width="40"
                  />
                </figure>
              </div>
              <section className="login-section OtpVerifyMs row mx-0">
                <div
                  className="sm-content col-12 px-5 mb-0"
                  style={{ marginBottom: isUpdate ? "50px" : "0px" }}
                >
                  <figure>
                    <img src={locationBigIcon} alt="" />
                  </figure>
                  <h2 className="mb-4"></h2>
                  <p>
                    Please provide us your
                    <b> preferred address for this delivery.</b>
                  </p>
                </div>
                {isUpdate ? (
                  <div className="col-12 mt-5 px-5">
                    <div className="selectInput mx-5">
                      <SelectInput
                        brand_highlight_color={
                          outletDetails?.settings?.brand_highlight_color
                        }
                        options={[
                          { nickname: "Select Delivery Location" },
                        ].concat(locations)}
                        value={options.deliveryLocation}
                        keyToCheck="nickname"
                        onChange={(e) =>
                          handleChange("deliveryLocation")({
                            target: { value: e.target.value },
                          })
                        }
                      />
                    </div>
                  </div>
                ) : null}
                {isUpdate ? (
                  <div className="col-12 mt-5 px-5">
                    <div className="text-align-left">
                      <span
                        className="anchor_link"
                        onClick={() => setIsUpdate(false)}
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                      >
                        ADD NEW ADDRESS
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="col-12 mt-0 px-5">
                  <form>
                    <div className="group mt-5">
                      <FloatingInput
                        label="LOCATION NICKNAME"
                        onChange={onChangeAddress("nickname")}
                        value={address.nickname}
                      />
                    </div>
                    <div className="group">
                      <FloatingInput
                        label="STREET ADDRESS"
                        onChange={onChangeAddress("street_address")}
                        value={address.street_address}
                      />
                    </div>

                    <div className="group">
                      <FloatingInput
                        label="UNIT NUMBER"
                        onChange={onChangeAddress("unit_number")}
                        value={address.unit_number}
                      />
                    </div>

                    <div className="group row d-flex">
                      <FloatingGroupInput
                        fullWidth
                        variant="standard"
                        className="mt-0"
                      >
                        <InputLabel>COUNTRY & CITY</InputLabel>
                        <div className="row mx-0 mt-16">
                          <div className="col-3 countrySelect selectBox">
                            <SelectInput
                              brand_highlight_color={
                                outletDetails?.settings?.brand_highlight_color
                              }
                              country={true}
                              options={countryList}
                              value={address.country}
                              keyToCheck="country_code"
                              onChange={onChangeAddress("country")}
                            />
                          </div>
                          <div className="col-9">
                            <Input
                              autoComplete="new-password"
                              fullWidth
                              onChange={onChangeAddress("city")}
                              value={address.city || " "}
                            />
                          </div>
                        </div>
                      </FloatingGroupInput>
                    </div>
                    <div className="group">
                      <FloatingInput
                        label="ZIP CODE"
                        onChange={onChangeAddress("zip_code")}
                        value={address.zip_code}
                      />
                    </div>

                    <div className="group sub-btn mb-0 d-flex justify-content-between">
                      <Button
                        type="button"
                        className="col-12 mr-1 mt-0 mb-5"
                        onClick={() => {
                          onPressAddUpdate();
                        }}
                        style={{
                          backgroundColor:
                            outletDetails?.settings?.brand_main_color,
                        }}
                      >
                        {isUpdate ? "Update" : "Add"}
                      </Button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </>
        </PopupModal>
        <Header
          title={"Make Payment"}
          backLink={"/"}
          backName={"Back to Summary"}
        />
        <section
          className={`user-section height-manage OtpVerifyMs mx-0 text-left${
            isLaptopScreen ? " d-flex" : ""
          }`}
        >
          {isLaptopScreen ? (
            <div className="col-3 padding-left-0">{getPaymentCon()}</div>
          ) : (
            getPaymentCon()
          )}

          {/* <div className="bottom-sec pb-5">
                        <div className="group sub-btn m-5 d-flex justify-content-between">
                            <Button
                                type="button"
                                className="col-12 mr-1"
                                onClick={() => history.push("/payment")}
                                style={{ backgroundColor: outletDetails?.settings?.brand_main_color }}
                            >
                                <span className="w-100">
                                    <span className="float-left pl-5">{currency} {parseFloat(cartBasicDetails?.totalCartAmount || 0).toFixed(2)}</span>
                                    <span className="float-right pr-5">PAYMENT</span>
                                </span>
                            </Button>
                        </div>
                    </div> */}
          {storeDetails && <PaymentScreen isLaptopScreen={isLaptopScreen} />}
        </section>
      </div>
    </>
  );
};
export default DeliveryInfo;
