import React from "react";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import {useSelector} from 'react-redux'
let OutlineInput = ({
  labelTop,
  beforeColor,
  afterColor,
  labelColor,
  brand_highlight_color,
  ...props
}) => {
  // const storeDetails = useSelector(state => state?.storeReducer?.storeDetails)
  // console.log('====================================');
  // console.log('default_highlight_color',storeDetails?.Merchant?.brand_main_color_,'default_main_color',storeDetails?.Merchant?.brand_highlight_color_);
  // console.log('====================================');
  const labelInputTop = labelTop ?? "-10px";
  const beforeInputColor = beforeColor ?? "#3e38a1";
  const afterInputColor = afterColor ?? "#d51b67";
  const afterLabelColor = labelColor ?? "#333";
  const CssTextField = styled(FormControl)({
    "& legend": {
      display: "none",
    },
    "& fieldset": {
      inset: "0px 0px 0px !important",
    },
    "& .MuiInputBase-root": {
      borderRadius: "40px",
    },
    "& .MuiInputBase-root input": {
      padding: "10.5px 20px",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d51b",
      borderWidth: "2px",
    },
    ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      // borderColor: brand_highlight_color || "#d517"
    }
  });
  return (
    <CssTextField variant="outlined" fullWidth>
      <OutlinedInput
        {...props}
        autoComplete="new-password"
        inputProps={{
          "aria-label": "weight",
        }}
      />
    </CssTextField>
  );
};
export default OutlineInput;
