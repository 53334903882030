import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import StepperProgress from "../Components/StepperProgress";
import { ellipse } from "../../Assets";
import { actions } from "../../Redux";
import moment from "moment";

const TrackStatus = ({ prevTab }) => {
  useEffect(() => {
    document.body.classList.add("white");
  }, []);
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [intervalID, setIntervalID] = useState(null);
  const { trackOrder } = useSelector((state) => state.paymentReducer);
  const { orderDetails, deliveryType } = useSelector(
    (state) => state.cartReducer
  );
  const {
    locations,
    availableLocations,
    selectedLocation,
    selectedStore,
    deliveryDate,
    deliveryTime,
  } = useSelector((state) => state.deliveryOptionsReducer);
  const deliveryDateTime = deliveryDate + " " + deliveryTime;
  const { outletDetails } = useSelector((state) => state.storeReducer);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const order = orderDetails.order ?? "";

  useEffect(() => {
    dispatch(
      actions.trackOrder({
        order_id: orderDetails.order.pos_order_id,
        order_offline_id: orderDetails.order.order_offline_id,
      })
    );
    let intervalID = setInterval(() => {
      if (!trackOrder?.status[3]?.time) {
        dispatch(
          actions.trackOrder({
            order_id: orderDetails.order.pos_order_id,
            order_offline_id: orderDetails.order.order_offline_id,
          })
        );
      }
    }, 1000 * 60);

    setIntervalID(intervalID);
    return () => {
      clearInterval(intervalID);
    };
  }, [orderDetails.order]);

  if (trackOrder?.status[3]?.time) {
    clearInterval(intervalID);
  }
  function deliveryInfo() {
    if (deliveryType.key === "pickup") return "Pickup";
    else if (!outletDetails?.settings?.preferred_delivery_partner || outletDetails?.settings?.preferred_delivery_partner == "0")
      return "Own Delivery";
    else
      return (
        <span>
          {" "}
          Delivery Partner: <b>Lalamove</b>{" "}
        </span>
      );
  }
  const steps = [
    {
      label: "ORDER RECEIVED",
      description: `11:23am`,
    },
    {
      label: "ORDER ACCEPTED BY RESTAURANT",
      description: `11:23am`,
    },
    {
      label: "RIDER ASSIGNED",
      description: `11:23am`,
    },
    {
      label: "OUT FOR DELIVERY",
      description: `11:23am`,
    },
    {
      label: "RIDER NEARBY",
      description: `11:23am`,
    },
    {
      label: "DELIVERED!",
      description: `11:53am`,
      caption: `11:53am`,
      icon: ellipse,
    },
  ];

  const step1 = [
    {
      name: "Order Received",
      time: "11:34 am",
    },
    {
      name: "Order Accepted by Restaurant",
      time: "11:34 am",
    },
    {
      name: "Rider Assigned",
      time: null,
    },
    {
      name: "Out for Delivery",
      time: null,
    },
    {
      name: "Rider Nearby",
      time: null,
    },
    {
      name: "Delivered",
      time: null,
    },
  ];
  const backLink = () => {
    navigate(-1);
  };
  return (
    <div className="wrapper colorGreyBg trackStatusScreen confirm-width">
      <Header
        title={"Order confirmation"}
        backLink={backLink}
        backName={"Back to Confirmation"}
      />
      <section className="user-section mx-0">
        <div className="TrackStatus">
          <div className="col-12">
            <p className="sm-title font-normal px-0 mb-1">
              <b>
                {deliveryType?.key == "delivery"
                  ? `Delivery on ${moment(deliveryDateTime).format(
                      "Do MMM"
                    )} at ${moment(deliveryDateTime).format("h:mm A")}`
                  : `Pickup scheduled for  ${moment(deliveryDateTime).format(
                      "Do MMM"
                    )} at ${moment(deliveryDateTime).format("h:mm A")}`}
              </b>
            </p>
          </div>
          <div className="col-12 statusTracking">
            <StepperProgress
              steps={trackOrder?.status || []}
              checkKey={"name"}
              type="trackStatus"
            />
          </div>
        </div>
        <div className="bottom-sec">
          <div className="col-12 my-0">
            <p className="sm-title">
              {deliveryInfo()}
              {/* if delivery parther lalamove
              Delivery Partner: <b>Lalamove</b>
              if delivery own
             Delivered by restaurant
              if pickup
              Pickup Order
               */}
            </p>
          </div>
          <div className="col-12">
            <span
              className="anchor_link bolder"
              style={{ color: outletDetails?.settings?.brand_highlight_color }}
              onClick={() => navigate("/help")}
            >
              HELP
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TrackStatus;
