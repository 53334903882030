import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  paymentTokenData: {},
  paymentTokengenerated: false,
  paymentIntent: null,
  trackOrder: null,
  whereIsMyOrder: null
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState
    case actionTypes.PAYMENT_INTENT_SUCCEEDED:
    case actionTypes.PAYMENT_TOKEN_SUCCEEDED:
    case actionTypes.TRACK_ORDER_DETAILS_SUCCEEDED:
    case actionTypes.WHERE_IS_MY_ORDER_SUCCEEDED:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default paymentReducer;
