import { API_URLS, customAxios } from "../config";

export const getMenuItems = payload => customAxios.post(API_URLS.getMenuItems, payload, {
    disableLoading: true,
  }).catch(ex => ({data:{message : "Some error occured, Please try after sometime."}}));
export const getOutletDetails = (query = '') => customAxios.get(API_URLS.getOutletDetails + query, {
    disableLoading: true,
  }).catch(ex => ({data:{message : "Some error occured, Please try after sometime."}}));
export const getStoreDetails = (query = '') => customAxios.get(API_URLS.getStoreDetails + query).catch(ex => ({data:{message : "Some error occured, Please try after sometime."}}));
export const getTagDetails = (query = '') => customAxios.get(API_URLS.getTagDetails + query).catch(ex => ({data:{message : "Some error occured, Please try after sometime."}}));

// eslint-disable-next-line import/no-anonymous-default-export
export default { getMenuItems, getOutletDetails, getStoreDetails };