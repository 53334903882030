import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import { actions } from "../../Redux";
import { useSelector } from "react-redux";
import { StyledSVG } from "../../Utils/style";
import { plusIcon, minusIcon } from "../../Assets";

import {
  addItemCartDetail,
  updateItemCartDetail,
  removeItemCartDetail,
  // getTableDetailByFilter,
  showNotificationWithTimeout,
  // emptyEditedItemDetail,
} from "../../Redux/actions";
import {
  getModifierActionName,
  getModifierTypeName,
  getModifierActionSign,
  checkNegNum,
  makeNegToPosNum,
  checkBundleItemExistInCart,
  checkItemExistInCart,
  groupByArray,
  getRandomString,
  getBundleItemOptionsPrice,
  imageExists,
  getUniTimeString,
  getServerImgUrl,
  checkArray,
} from "../../helpers";
import { squreImgLgPngIcon } from "../../Assets";
import { Fragment } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";

function EditBundleItem({
  loading,
  selected_item_detail,
  edited_item_detail,
  replaceItemCartDetail,
  table_detail,
  menuItems,
  // filters,
  filter_tag_detail,
  cart_detail,
  addItemCartDetail,
  updateItemCartDetail,
  // getTableDetailByFilter,
  showNotificationWithTimeout,
  removeItemCartDetail,
  editItemCartDetails,
}) {
  // const history = useHistory();
  const navigate = useNavigate();

  console.log("edited_item_detail", edited_item_detail);
  console.log("selected_item_detail", selected_item_detail);

  // const isContainDiscountArr =
  //   selected_item_detail?.DiscountModifiers.findIndex(
  //     (p) => p.action == "discount"
  //   ) > -1
  //     ? true
  //     : false;

  const outletDetails = useSelector(
    (state) => state.storeReducer?.outletDetails
  );
  const serviceChargePer = parseFloat(menuItems?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(menuItems?.SalesTaxRate ?? 0);
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";

  const [wrapperClass, setWrapperClass] = useState("");
  // const cartItemKey =
  //   cart_detail && cart_detail.length > 0 && selected_item_detail?.id
  //     ? cart_detail.findIndex((p) => p.id == selected_item_detail?.id)
  //     : -1;

  // console.log("selected_item_detail", selected_item_detail);
  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty(
      "--store-color",
      `${outletDetails?.settings?.brand_main_color}`
    );
  }, [
    outletDetails?.settings?.brand_highlight_color,
    outletDetails?.settings?.brand_main_color,
  ]);
  function setBundleItems() {
    let res = [];
    if (edited_item_detail?.selectedBundleItem?.length > 0) {
      edited_item_detail?.selectedBundleItem?.map((item, index) => {
        // if (index == 0) {
        const bundleOptnKey =
          selected_item_detail?.BundledOptionSeries?.findIndex(
            (p) => p.id == item?.option_series_id
          );
        if (bundleOptnKey > -1) {
          const bundleOptn =
            selected_item_detail?.BundledOptionSeries[bundleOptnKey];
          const bundleOptnItmKey = bundleOptn?.BundledMenuItem?.findIndex(
            (p) => p.id == item?.id
          );
          if (bundleOptnItmKey > -1) {
            res.push(bundleOptn?.BundledMenuItem[bundleOptnItmKey]);
          }
        }
        // }
      });
    } else if (
      selected_item_detail?.recomItem?.bundle_item_ids &&
      selected_item_detail?.recomItem?.bundle_item_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.bundle_item_ids?.map((bundleItemId) => {
        const bundleItemIndex =
          selected_item_detail?.BundledOptionSeries?.findIndex((p) => {
            // console.log("catbundleItem", p?.BundledMenuItem, bundleItemId);
            return (
              p?.BundledMenuItem.findIndex(
                (q) => q.bundle_menu_item_id == bundleItemId?.menu_item_id
              ) > -1
            );
          });
        if (bundleItemIndex > -1) {
          const bundleItem =
            selected_item_detail?.BundledOptionSeries?.[bundleItemIndex];
          const bundleOptionItem = bundleItem?.BundledMenuItem?.findIndex(
            (s) => s.bundle_menu_item_id == bundleItemId?.menu_item_id
          );
          if (
            bundleOptionItem > -1 &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem]
          ) {
            res.push(bundleItem?.BundledMenuItem?.[bundleOptionItem]);
          }
          // console.log("catbundleItem", bundleItem);
        }
      });
    }

    if (selected_item_detail?.BundledOptionSeries?.length > 0) {
      selected_item_detail?.BundledOptionSeries?.filter(
        (p) => p?.option_series_type == ""
      )?.map((item, index) => {
        if (item?.BundledMenuItem?.length > 0) {
          item?.BundledMenuItem?.map((option) => {
            // prevent dublicate from automatic adding fixed item
            if (
              selectedBundleItem.findIndex((p) => p.id == option.id) === -1 &&
              res.findIndex((p) => p.id == option.id) === -1
            ) {
              res.push(option);
            }
            // prevent dublicate from automatic adding fixed item END
          });
        }
      });
    }
    if (res.length > 0) {
      setSelectedBundleItem(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  }

  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.menu_price?.item_price
            ? selected_item_detail?.menu_price?.item_price
            : 0
        );

  const [currentItemQytEdit, setCurrentItemQytEdit] = useState(false);
  const [currentItemQyt, setCurrentItemQyt] = useState(
    edited_item_detail?.quantity != undefined ||
      edited_item_detail?.quantity > 0
      ? edited_item_detail?.quantity
      : 1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;
  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [modifierChanged, setModifierChanged] = useState(false);

  const [showModifiers, setShowModifiers] = useState(false);
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [hideHeader, setHideHeader] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBundleItem, setSelectedBundleItem] = useState([]);

  const [imageArr, setImageArr] = useState([]);
  const imageArrRef = useRef(imageArr);
  imageArrRef.current = imageArr;

  const showNoteCon =
    table_detail?.QrOrdering?.hide_note !== "yes" ? true : false;

  const qytEditInputRef = useRef(null);

  useEffect(() => {
    if (currentItemQytEdit) {
      qytEditInputRef?.current?.focus();
    }
  }, [currentItemQytEdit]);

  function getImages(images) {
    let arr = images?.map(async (img) => {
      if (img?.file_name) {
        let a = await imageExists(getServerImgUrl(img?.file_name));
        return a;
      }
    });
    Promise.all(arr)
      .then((resp) => {
        if (resp?.length > 0) {
          setImageArr(resp);
        } else {
          setHideImages(true);
          setHideHeader(true);
          setWrapperClass("scroll-active");
        }
      })
      .catch((er) => {
        setHideImages(true);
        setHideHeader(true);
        setWrapperClass("scroll-active");
      });
  }

  // console.log("selectedBundleItem", selectedBundleItem);

  const [modifierArray, setModifierArray] = useState([]);

  const getPreModifiers = () => {
    let res = [];
    if (
      edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
    ) {
      res = edited_item_detail?.modifierArray;
    } else if (
      selected_item_detail?.BundledOptionSeries &&
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.recomItem?.bundle_item_ids &&
      selected_item_detail?.recomItem?.bundle_item_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.bundle_item_ids?.map((bundleItemId) => {
        const bundleItemIndex =
          selected_item_detail?.BundledOptionSeries?.findIndex((p) => {
            // console.log("catbundleItem", p?.BundledMenuItem, bundleItemId);
            return (
              p?.BundledMenuItem.findIndex(
                (q) => q.bundle_menu_item_id == bundleItemId?.menu_item_id
              ) > -1
            );
          });
        if (bundleItemIndex > -1) {
          const bundleItem =
            selected_item_detail?.BundledOptionSeries?.[bundleItemIndex];
          const bundleOptionItem = bundleItem?.BundledMenuItem?.findIndex(
            (s) => s.bundle_menu_item_id == bundleItemId?.menu_item_id
          );
          if (
            bundleOptionItem > -1 &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem] &&
            bundleItem?.BundledMenuItem?.[bundleOptionItem]
              ?.PosMenuitemsModifier?.length > 0
          ) {
            bundleItem?.BundledMenuItem?.[
              bundleOptionItem
            ]?.PosMenuitemsModifier?.filter((r) => {
              // console.log("bundleItemId", r?.id, bundleItemId?.modifiers_id);
              return bundleItemId?.modifiers_id.includes(r?.id);
            })?.map((modItem) => {
              const bundleName = modItem?.modifier_name ?? "";
              const modifierType = modItem?.type ?? "";
              const modifierAction = modItem?.action ?? "";
              const modifierName = getModifierTypeName(modifierType, modItem);
              const unitPrice =
                modItem?.unit_price &&
                modItem?.unit_price !== null &&
                parseFloat(modItem?.unit_price) > 0 &&
                modItem?.unit_price !== ""
                  ? parseFloat(modItem?.unit_price)
                  : 0;
              res.push({
                id: modItem?.id,
                modifier_id: modItem?.id,
                modifier_group_id: modItem?.modifier_group_id,
                action: modifierAction,
                name: modifierName,
                unit_price: parseFloat(unitPrice.toFixed(2)),
                modifier_price: parseFloat(unitPrice.toFixed(2)),
                modifier_quantity: 1,
                menu_item_id: modItem?.menu_item_id,
              });
            });
            // console.log(
            //   "catbundleItem",
            //   bundleItem?.BundledMenuItem?.[bundleOptionItem]
            // );
          }
        }
      });
    }
    if (res.length > 0) {
      setModifierArray(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  };

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddActionPrice =
      selectedBundleItem.length > 0
        ? selectedBundleItem
            .filter((item) => item.action_price == "add")
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemoveActionPrice =
      selectedBundleItem.length > 0
        ? selectedBundleItem
            .filter((item) => item.action_price == "remove")
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddActionPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemoveActionPrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
  }

  useEffect(() => {
    calTotalPrice();
  }, [selectedItemPrice, modifierChanged]);

  useEffect(() => {
    getImages(
      selected_item_detail?.content?.length > 0
        ? selected_item_detail?.content
        : selected_item_detail?.image
        ? [{ file_name: selected_item_detail?.image }]
        : []
    );
    getPreModifiers();
    setBundleItems();

    return () => {
      editItemCartDetails({
        editItemDetails: null,
      });
    };
  }, []);

  // console.log("modifierArray", modifierArray);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        hideImages == false &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const editItemQyt = (quantity) => {
    setCurrentItemQyt(quantity);
    setModifierChanged(!modifierChanged);
  };

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  // function checkModifiersLength() {
  //   let res = false;
  //   if (selected_item_detail?.BundledOptionSeries?.length > 0) {
  //     for (
  //       let i = 0;
  //       i < selected_item_detail?.BundledOptionSeries?.length;
  //       i++
  //     ) {
  //       const item = selected_item_detail?.BundledOptionSeries?.[i];
  //       if (
  //         item?.BundledMenuItem?.length > 0 &&
  //         item?.BundledMenuItem?.findIndex(
  //           (p) => p?.PosMenuitemsModifier?.length > 0
  //         ) > -1
  //       ) {
  //         res = true;
  //         break;
  //       }
  //     }
  //   }
  //   return res;
  // }

  function checkModifiersLength() {
    let res = false;
    if (
      selectedBundleItem?.filter((item) => {
        let filterRes = item?.modifier_availability != "4";
        if (filterRes) {
          if (
            (item?.modifier_availability != "3" &&
              item?.PosMenuitemsModifier &&
              checkArray(
                item?.PosMenuitemsModifier.filter((pq) => pq.action !== "size")
              )) ||
            (item?.modifier_availability != "2" &&
              item?.StandardModifier &&
              checkArray(item?.StandardModifier))
          ) {
            filterRes = true;
          } else {
            filterRes = false;
          }
        }
        // if (
        //   selected_item_detail?.hide_fixed_items_qr == "yes" &&
        //   item?.option_series_name == "Fixed Items"
        // ) {
        //   filterRes = false;
        // }
        return filterRes;
      }).length > 0
    ) {
      res = true;
    }
    return res;
  }

  function getItemModifiers() {
    let res = null;
    if (selectedBundleItem?.length > 0) {
      res = selectedBundleItem
        ?.filter((item) => {
          let showModCon = true;
          if (!showNoteCon) {
            showModCon =
              ((item?.PosMenuitemsModifier &&
                checkArray(item?.PosMenuitemsModifier)) ||
                (item?.StandardModifier &&
                  checkArray(item?.StandardModifier))) &&
              checkArray(
                item?.PosMenuitemsModifier.filter(
                  (pr) => pr?.modifier_availability != "4"
                )
              );
          }
          // if (
          //   selected_item_detail?.hide_fixed_items_qr == "yes" &&
          //   item?.option_series_name == "Fixed Items"
          // ) {
          //   showModCon = false;
          // }
          return showModCon;
        })
        .map((element, index) => {
          return (
            <>
              <h5 className="sm-title">
                {" "}
                {element?.bundle_menu_item_name ?? ""}
                {" Modifiers"}{" "}
              </h5>
              <div className="selectors" style={{ paddingBottom: "10px" }}>
                {/* 
                 commented by ajay 
                {element?.PosMenuitemsModifier.findIndex(
                  (p) => p.action == "size"
                ) > -1 && <>{getItemModifierOptions(element, "size")}</>} */}
                {element?.modifier_availability != "3" &&
                  getItemModifierOptions(element, "")}
                {element?.modifier_availability != "2" &&
                  getComponentStandardModifiers(element)}
              </div>
              {showNoteCon && (
                <>
                  <h5 className="sm-title">Notes:</h5>
                  <div className="message_col">
                    <label>Notes:</label>
                    <textarea
                      id={`comment_${element.id}`}
                      name={`comment_${element.id}`}
                      className={"form-control"}
                      placeholder="Add note here."
                      type="text"
                      onChange={(el) => {
                        const itemIndex = selectedBundleItem.findIndex(
                          (p) => p.id == element.id
                        );
                        let newArr = [...selectedBundleItem]; // copying the old datas array
                        newArr[itemIndex]["comment"] = el.target.value;
                        setSelectedBundleItem(newArr);
                      }}
                    >
                      {element?.comment ?? ""}
                    </textarea>
                  </div>
                </>
              )}
            </>
          );
        });
    }
    return res;
  }

  function getComponentStandardModifiers(component) {
    console.log("component", component);
    let res = null;
    if (component?.StandardModifier && component?.StandardModifier.length > 0) {
      res = component?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_com_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          menu_item_id: component?.bundle_menu_item_id,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label htmlFor={`standard_com_modifier_${modifierId}`}>
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label
                    className="modifierLabel"
                    htmlFor={`standard_modifier_${modifierId}`}
                  >
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function checkModifierItemOption(element) {
    let res = false;
    if (
      modifierArray.length > 0 &&
      modifierArray.findIndex((p) => p.id == element.id) > -1
    ) {
      res = true;
    }
    return res;
  }

  function checkMustSelectedItems() {
    let res = { status: false, limit: 0, name: "" };
    if (
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.BundledOptionSeries?.findIndex(
        (p) => p.option_series_type == ""
      ) > -1
    ) {
      const includeBundleSeries =
        selected_item_detail?.BundledOptionSeries?.filter(
          (p) => p.option_series_type == ""
        );
      for (let i = 0; i < includeBundleSeries?.length; i++) {
        const bundle = includeBundleSeries?.[i];
        const limit = parseInt(bundle?.BundledMenuItem?.length ?? 0);
        if (
          limit &&
          selectedBundleItem.filter((p) => p.option_series_id == bundle?.id)
            .length < limit
        ) {
          res = { status: true, limit: limit, name: bundle?.name };
          break;
        }
      }
    }
    if (
      selected_item_detail?.BundledOptionSeries?.length > 0 &&
      selected_item_detail?.BundledOptionSeries?.findIndex(
        (p) => p.option_series_type == "ms"
      ) > -1
    ) {
      const mustBundleSeries =
        selected_item_detail?.BundledOptionSeries?.filter(
          (p) => p.option_series_type == "ms"
        );
      for (let i = 0; i < mustBundleSeries?.length; i++) {
        const bundle = mustBundleSeries?.[i];
        const limit = parseInt(bundle?.series_total_allowed);
        if (
          selectedBundleItem.filter((p) => p.option_series_id == bundle?.id)
            .length < limit
        ) {
          res = { status: true, limit: limit, name: bundle?.name };
          break;
        }
      }
    }
    return res;
  }

  // function getItemDiscountModifiers() {
  //   let res = null;
  //   if (
  //     selected_item_detail?.DiscountModifiers &&
  //     selected_item_detail?.DiscountModifiers.length > 0
  //   ) {
  //     const discountModifiersArr =
  //       selected_item_detail?.DiscountModifiers.filter(
  //         (p) => p.action == "discount"
  //       ).map((element, index) => {
  //         const modifierType = element?.type ?? "";
  //         const modifierAction = element?.action ?? "";
  //         const modifierName = getModifierTypeName(modifierType, element);
  //         const unitPrice =
  //           element?.unit_price &&
  //           element?.unit_price !== null &&
  //           element?.unit_price !== ""
  //             ? parseFloat(element?.unit_price)
  //             : 0;
  //         return (
  //           <li>
  //             <input
  //               type="radio"
  //               name="select_options"
  //               id={`modifier_${element?.id}`}
  //               className="check"
  //               onClick={() => {
  //                 const discountEl = {
  //                   id: element?.id,
  //                   modifier_id: element?.id,
  //                   modifier_group_id: element?.modifier_group_id,
  //                   action: modifierAction,
  //                   name: modifierName,
  //                   unit_price: parseFloat(unitPrice),
  //                   modifier_price: parseFloat(unitPrice),
  //                   modifier_quantity: 1,
  //                 };
  //                 const existIndex = modifierArray.findIndex(
  //                   (p) => p.action == "discount"
  //                 );
  //                 if (existIndex > -1) {
  //                   setModifierArray([
  //                     ...modifierArray.slice(0, existIndex),
  //                     discountEl,
  //                     ...modifierArray.slice(existIndex + 1),
  //                   ]);
  //                 } else {
  //                   setModifierArray([...modifierArray, discountEl]);
  //                 }
  //                 setSelectedItemPrice(parseFloat(unitPrice));
  //               }}
  //               checked={
  //                 modifierArray.findIndex((p) => p.id == element?.id) > -1
  //               }
  //             />
  //             <label htmlFor={`modifier_${element?.id}`}>
  //               {element?.amount_per_item} x{" "}
  //               {selected_item_detail?.menu_item_name}
  //             </label>
  //             <span className="price">
  //               {`${table_detail?.currency ?? ""}`}
  //               {parseFloat(unitPrice).toFixed(2)}
  //             </span>
  //           </li>
  //         );
  //       });
  //     res = (
  //       <div className="options">
  //         <ul>
  //           <li>
  //             <input
  //               type="radio"
  //               name="select_options"
  //               id={`default_options`}
  //               className="check"
  //               onClick={() => {
  //                 setModifierArray(
  //                   modifierArray.filter(
  //                     (modifier) => modifier.action != "discount"
  //                   )
  //                 );
  //                 setSelectedItemPrice(defaultSelectedItemPrice);
  //               }}
  //               checked={
  //                 modifierArray.findIndex((p) => p.action == "discount") < 0
  //               }
  //             />
  //             <label htmlFor="default_options">
  //               1 x {selected_item_detail?.menu_item_name}
  //             </label>
  //             <span className="price">
  //               {`${table_detail?.currency ?? ""}`}
  //               {parseFloat(defaultSelectedItemPrice).toFixed(2)}
  //             </span>
  //           </li>
  //           {discountModifiersArr}
  //         </ul>
  //       </div>
  //     );
  //   }
  //   return res;
  // }

  function AddToCartHandler() {
    if (selected_item_detail?.id) {
      const checkMustSelectItems = checkMustSelectedItems();
      if (checkMustSelectItems?.status === true) {
        toast.error(
          `Please select atleast ${checkMustSelectItems?.limit} under "${checkMustSelectItems?.name}" section!`,
          "error"
        );
        return;
      }
      const totalLengthModifiers = checkModifiersLength();
      if (showModifiers || totalLengthModifiers === false) {
        const modifierAddActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "add")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemoveActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "remove")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierAddPrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "add" || item.action == "swap")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemovePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "remove")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierSizePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "size")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifersPrice =
          modifierAddActionPrice -
          modifierRemoveActionPrice +
          modifierAddPrice -
          modifierRemovePrice -
          modifierSizePrice;
        let actualItemPrice = selectedItemPrice;
        if (selected_item_detail?.promotions) {
          // total amount changes with percentage discount
          if (
            modifersPrice !== 0 &&
            selected_item_detail?.promotions.type == "Percentage"
          ) {
            let discountPrice =
              ((selected_item_detail?.promotions.acutalPrice + modifersPrice) *
                parseFloat(selected_item_detail?.promotions.amount)) /
              100;
            actualItemPrice =
              selected_item_detail?.promotions.acutalPrice - discountPrice >= 0
                ? selected_item_detail?.promotions.acutalPrice - discountPrice
                : 0;
          }
        }
        const singlePriceWithModifier = actualItemPrice + modifersPrice;
        const priceWithModifier = singlePriceWithModifier * currentItemQyt;
        // const serviceChargeAmount =
        // selected_item_detail?.menu_price?.service_charge == "1" ||
        // selected_item_detail?.menu_price?.service_charge == true
        //     ? parseFloat(
        //         (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
        //       )
        //     : 0;
        const serviceChargeAmount = 0;
        const salesTaxAmount =
          selected_item_detail?.menu_price?.sales_tax == "1" ||
          selected_item_detail?.menu_price?.sales_tax == true
            ? parseFloat(
                ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
              )
            : 0;
        const BundledMenuItem = selectedBundleItem.map((item) => {
          return {
            amount: parseFloat(item?.amount ?? 0),
            amount_without_modifiers: parseFloat(actualItemPrice),
            comment: item?.comment ?? "",
            is_hold: false,
            option_series_id: parseInt(item?.option_series_id),
            bundle_menu_item_name: item?.bundle_menu_item_name,
            menu_item_pos_name: item?.menu_item_pos_name,
            void: 0,
            option_series_name: item?.option_series_name,
            id: parseInt(item?.id),
            quantity: currentItemQyt,
            perBundleQty: item?.quantity ?? "",
            //
            category_id: item?.category_id ?? "",
            sub_category_id: item?.sub_category_id ?? "",
            sub_class_id: item?.sub_class_id ?? "",
            //
            // is_fired: item?.bundle_hide_fixed_items === "yes" ? true : false,
            is_fired:
              item?.bundle_hide_fixed_items == "yes" &&
              modifierArray.findIndex(
                (p) => p.menu_item_id == item.bundle_menu_item_id
              ) == -1
                ? true
                : false,
                fired_by:
                item?.bundle_hide_fixed_items == "yes" &&
                modifierArray.findIndex(
                  (p) => p.menu_item_id == item.bundle_menu_item_id
                ) == -1
                  ? "Autofire"
                  : "",
              firetime:
                item?.bundle_hide_fixed_items == "yes" &&
                modifierArray.findIndex(
                  (p) => p.menu_item_id == item.bundle_menu_item_id
                ) == -1
                  ? moment()
                      .utc()
                      .utcOffset(timezone)
                      .format("yyyy/MM/DD hh:mm:ss")
                  : "",    
            bundleName: selected_item_detail?.menu_item_name ?? "",
            //
            bundlePosName: selected_item_detail?.pos_name,
            //
            bundle_menu_item_id: parseInt(item?.bundle_menu_item_id),
            offlineID: `${getUniTimeString()}${parseInt(item?.id)}`,
            sort: item?.sort ?? "",
            type: item?.type ?? "",
            action_price: item?.action_price ?? "",
            modifier_price: item?.modifier_price
              ? parseFloat(item?.modifier_price)
              : 0,
            bundledMenuitemModifers: modifierArray.filter(
              (p) => p.menu_item_id == item.bundle_menu_item_id
            ),
          };
        });
        let item = {
          id: selected_item_detail?.id,
          offline_id: getRandomString(),
          name: selected_item_detail?.pos_name,
          pos_name: selected_item_detail?.menu_item_name,
          menu_class_id: selected_item_detail?.menu_class_id,
          menu_class_name: selected_item_detail?.menu_class_name,
          //
          bundlePosName: selected_item_detail?.pos_name,
          //
          bundleName: selected_item_detail?.menu_item_name,
          image: selected_item_detail?.image,
          firetime: null,
          createDate: moment().format("YYYY/MM/DD HH:mm:ss"),
          is_added: false,
          is_fired: false,
          is_hold: false,
          is_service_charge:
            selected_item_detail?.menu_price?.service_charge == "1" ||
            selected_item_detail?.menu_price?.service_charge == true
              ? true
              : false,
          is_sales_tax:
            selected_item_detail?.menu_price?.sales_tax == "1" ||
            selected_item_detail?.menu_price?.sales_tax == true
              ? true
              : false,
          quantity: currentItemQyt,
          amount: selected_item_detail?.promotions?.acutalPrice
            ? selected_item_detail?.promotions?.acutalPrice
            : singlePriceWithModifier,
          amount_without_modifiers: parseFloat(selectedItemPrice),
          total: priceWithModifier,
          item_amount: selectedItemPrice,
          actual_menu_price: parseFloat(
            selected_item_detail?.promotions?.acutalPrice
              ? selected_item_detail?.promotions?.acutalPrice
              : selected_item_detail?.menu_price?.item_price ?? 0
          ),
          hide_fixed_items: selected_item_detail?.hide_fixed_items,
          hide_fixed_items_qr: selected_item_detail?.hide_fixed_items_qr,
          menu_item_type: selected_item_detail?.menu_item_type ?? "",
          kitchen_print_preference:
            selected_item_detail?.kitchen_print_preference ?? null,
          menu_price: selected_item_detail?.menu_price?.item_price ?? "",
          service_charge_amount: serviceChargeAmount,
          sales_tax_amount: salesTaxAmount,
          catID: selected_item_detail?.other_category_id ?? "",
          sub_category_id: selected_item_detail?.sub_category_id ?? "",
          sub_class_id: selected_item_detail?.sub_class_id ?? "",
          modifierArray: modifierArray.sort(function (a, b) {
            return a.id - b.id;
          }),
          BundledMenuItem: BundledMenuItem.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          ),
          selectedBundleItem: selectedBundleItem
            .sort(function (a, b) {
              return a.id - b.id;
            })
            //
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            ),
        };
        if (selected_item_detail?.promotions) {
          const promoObj = selected_item_detail?.promotions;
          if (modifersPrice !== 0 && promoObj.type == "Percentage") {
            let discountPrice =
              ((promoObj.acutalPrice + modifersPrice) *
                parseFloat(promoObj.amount)) /
              100;
            promoObj.promotionTotaldiscount =
              parseFloat(discountPrice) * currentItemQyt;
            promoObj.discountPerItem = discountPrice;
          }
          if (promoObj.acutalPrice < promoObj.discountPerItem) {
            promoObj.discountPerItem = promoObj.acutalPrice;
            promoObj.promotionTotaldiscount =
              parseFloat(promoObj.acutalPrice) * currentItemQyt;
          }
          item.promotions = [promoObj];
        }
        const checkItemExistInCartKey = checkBundleItemExistInCart(
          item,
          cart_detail
        );
        if (
          (edited_item_detail !== null &&
            edited_item_detail?.offline_id !== undefined) ||
          checkItemExistInCartKey > -1
        ) {
          const existOffId =
            checkItemExistInCartKey > -1
              ? checkItemExistInCartKey
              : cart_detail?.findIndex(
                  (p) => p.offline_id == edited_item_detail.offline_id
                );
          if (existOffId > -1) {
            updateItemCartDetail({ index: existOffId, item });
            // replaceItemCartDetail({ item, selected_item_detail });
          }
        }
        // getTableDetailByFilter(
        //   table_detail?.PosTableMaster?.qr_code_id,
        //   // filters,
        //   ""
        // );
        navigate(
          // `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
          -1
        );
      } else {
        if (document.getElementById(`item-details-container`)) {
          document
            .getElementById(`item-details-container`)
            .scroll({ top: 0, behavior: "smooth" });
        }
        setShowModifiers(true);
      }
    }

    return;
  }

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        )
        .map((element, index) => {
          const bundleName = element?.modifier_name ?? "";
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            parseFloat(element?.unit_price) > 0 &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <div
              className="options"
              key={index}
              onClick={(el) => {
                const elArr = {
                  id: element?.id,
                  modifier_id: element?.id,
                  modifier_group_id: element?.modifier_group_id,
                  action: modifierAction,
                  name: modifierName,
                  unit_price: parseFloat(unitPrice.toFixed(2)),
                  modifier_price: parseFloat(unitPrice.toFixed(2)),
                  modifier_quantity: 1,
                  menu_item_id: element?.menu_item_id,
                };

                if (modifierArray.findIndex((p) => p.id == element?.id) > -1) {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.id != element?.id
                    )
                  );
                  setModifierChanged(!modifierChanged);
                } else {
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == modifierAction
                  );
                  if (existIndex > -1 && modifierAction == "size") {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      elArr,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, elArr]);
                  }
                  setModifierChanged(!modifierChanged);
                }
              }}
            >
              <input
                id={`bundle_${element.menu_item_id}`}
                name={`bundle_${element.menu_item_id}`}
                type="checkbox"
                className="check"
                checked={checkModifierItemOption(element)}
              />
              <label className="modifierLabel" htmlFor={`bundle_${index}`}>
                {" "}
                {bundleName}
              </label>

              {element?.unit_price !== null &&
                element?.unit_price !== "" &&
                (element?.unit_price > 0 || element?.action == "size") &&
                parseFloat(unitPrice) > 0 && (
                  <span className="price-sm">
                    {getModifierActionSign(modifierAction)}{" "}
                    {checkNegNum(element.unit_price) ? "- " : ""}
                    {`${table_detail?.currency ?? ""}`}
                    {parseFloat(
                      checkNegNum(element.unit_price)
                        ? makeNegToPosNum(element.unit_price)
                        : element.unit_price
                    ).toFixed(2)}
                  </span>
                )}
            </div>
          );
        });
    }
    return res;
  }

  function getItemModifierOptions(bundleItem, action = "") {
    let res = null;
    if (
      bundleItem?.PosMenuitemsModifier &&
      bundleItem?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        bundleItem?.PosMenuitemsModifier.sort(function (a, b) {
          return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
        }).filter((p) =>
          action === "size"
            ? p.action == "size"
            : p.action == "add" || p.action == "remove" || p.action == "swap"
        ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title pl-1 pb-0">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>
                {getItemModifiersItems(group, action)}
              </>
            );
        });
      }
    }
    return res;
  }

  function checkBundleItemOption(element) {
    let res = false;
    if (
      selectedBundleItem.length > 0 &&
      selectedBundleItem.findIndex((p) => p.id == element.id) > -1
    ) {
      res = true;
    }
    return res;
  }

  function getBundleItemOptions(bundleItem) {
    let res = null;
    if (bundleItem?.BundledMenuItem && bundleItem?.BundledMenuItem.length > 0) {
      res = bundleItem?.BundledMenuItem.sort(function (a, b) {
        return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
      }).map((element, index) => {
        const bundleName = element?.bundle_menu_item_name ?? "";
        const bundleQuantity = element?.quantity
          ? parseInt(element?.quantity)
          : 1;
        const bundleType = element?.type ?? "";
        const bundleAction = element?.action ?? "";
        return (
          <div
            className="options"
            key={index}
            onClick={(el) => {
              if (bundleItem?.option_series_type == "") {
                return;
              }
              const totalCountItems = selectedBundleItem.filter(
                (p) => p.option_series_id == element.option_series_id
              ).length;
              const selectUpTO =
                bundleItem?.series_total_allowed == null
                  ? parseInt(bundleItem?.BundledMenuItem?.length ?? 1)
                  : parseInt(bundleItem?.series_total_allowed ?? 1);
              if (!checkBundleItemOption(element)) {
                if (totalCountItems == selectUpTO) {
                  const existIndex = selectedBundleItem.findIndex(
                    (p) => p.option_series_id == element.option_series_id
                  );
                  if (existIndex > -1) {
                    setSelectedBundleItem([
                      ...selectedBundleItem.slice(0, existIndex),
                      element,
                      ...selectedBundleItem.slice(existIndex + 1),
                    ]);
                  }
                } else {
                  setSelectedBundleItem([...selectedBundleItem, element]);
                }
              } else {
                setSelectedBundleItem(
                  selectedBundleItem.filter((p) => p.id != element.id)
                );
              }
              setModifierChanged(!modifierChanged);
            }}
          >
            <input
              id={`bundle_${element.option_series_id}`}
              name={`bundle_${element.option_series_id}`}
              type="checkbox"
              className="check"
              checked={checkBundleItemOption(element)}
            />
            <label className="modifierLabel" htmlFor={`bundle_${index}`}>
              {" "}
              {bundleName} {bundleQuantity > 1 ? `x ${bundleQuantity}` : ""}
            </label>
            {getBundleItemOptionsPrice(element)}
          </div>
        );
      });
    }
    return res;
  }

  function checkMustSelectText(bundleEl) {
    let res = null;
    if (
      bundleEl?.series_total_allowed &&
      parseInt(bundleEl?.series_total_allowed) > 0
    ) {
      if (bundleEl?.option_series_type == "ms") {
        res = (
          <p>{`(Must Select ${parseInt(bundleEl?.series_total_allowed)})`}</p>
        );
      } else if (parseInt(bundleEl?.series_total_allowed) > 0) {
        res = (
          <p>{`(Select Up to ${parseInt(bundleEl?.series_total_allowed)})`}</p>
        );
      }
    }
    return res;
  }

  function getBundleItems() {
    let res = null;
    if (
      selected_item_detail?.BundledOptionSeries &&
      selected_item_detail?.BundledOptionSeries.length > 0
    ) {
      // sorting
      res = selected_item_detail?.BundledOptionSeries.sort(function (a, b) {
        return parseInt(a?.sort ?? 0) - parseInt(b?.sort ?? 0);
      });

      // setting modifier array to last
      const modifierNum = selected_item_detail?.BundledOptionSeries.findIndex(
        (el) => el.name === "Modifier"
      );
      const deepCloneArr = [...selected_item_detail?.BundledOptionSeries];
      const modifierEl = deepCloneArr.splice(modifierNum, 1);
      deepCloneArr.push(modifierEl[0]);

      res = deepCloneArr.map((element, index) => {
        return (
          <Fragment key={index}>
            <h5
              className={`sm-title ${
                selected_item_detail?.hide_fixed_items_qr === "yes" &&
                element.name === "Includes"
                  ? "d-none"
                  : ""
              }`}
            >
              {" "}
              {element?.name ?? ""} {checkMustSelectText(element)}{" "}
            </h5>
            <div
              className={`selectors ${
                selected_item_detail?.hide_fixed_items_qr === "yes" &&
                element.name === "Includes"
                  ? "d-none"
                  : ""
              }`}
              style={{ paddingBottom: "10px" }}
            >
              {getBundleItemOptions(element)}
            </div>
          </Fragment>
        );
      });
    }
    return res;
  }

  function getItemTags() {
    let res = null;
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = <span className="tags">Popular</span>;
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = <span className="tags blue">Trending</span>;
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            const fullFilterName =
              filterTag?.name && filterTag?.name !== "" ? filterTag?.name : "";
            return (
              <li
                style={style}
                className={className}
                key={index}
                data-tip={`${fullFilterName}`}
              >
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function getAddItemDetail() {
    if (table_detail?.Merchant) {
      return (
        <div className={"wrapper " + wrapperClass}>
          <ReactTooltip effect="solid" />
          <section className="add-items_sec">
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 270 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  style={{
                    height: `${x}px`,
                  }}
                >
                  <div
                    onClick={() =>
                      navigate(
                        // `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                        -1
                      )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <div className="image-sec">
                    <ImageSlider
                      images={imageArr}
                      onOpen={(index) => {
                        setShowImageView(true);
                        setCurrentImageIndex(index);
                      }}
                      // isOpen={true}
                    />
                    {showImageView === true && (
                      <ImageViewer
                        images={imageArr}
                        isOpen={showImageView}
                        currentIndex={currentImageIndex}
                        onClose={() => setShowImageView(false)}
                      />
                    )}
                    {/* <figure>
                      {" "}
                      <ImageTag
                        src={selected_item_detail?.image}
                        defaultimage={table_detail?.Merchant?.logo}
                      />
                    </figure> */}
                    {getItemTags()}
                  </div>
                </div>
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 0 }}
              style={{
                x: spring(hideHeader === false ? 0 : 100, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className={`foobar-header row mx-0 ${
                    hideHeader === true ? "touch-top" : ""
                  }`}
                  style={{
                    height: `${x}%`,
                  }}
                >
                  <div
                    onClick={() =>
                      navigate(
                        // `/get_table_details/${table_detail?.PosTableMaster?.qr_code_id}`
                        -1
                      )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <h5 className="name col-9">
                    {selected_item_detail?.menu_item_name}
                  </h5>
                  <span className="price col-3 px-0 text-right h5">
                    {" "}
                    {/* {!isContainDiscountArr && (
                      <>
                        <small className="currency">{`${
                          table_detail?.currency ?? ""
                        }`}</small>
                        {`${
                          selected_item_detail?.dinein_price?.DineIn
                            ? parseFloat(
                                selected_item_detail?.dinein_price?.DineIn
                              ).toFixed(2)
                            : "0.00"
                        }`}
                      </>
                    )} */}
                  </span>
                  {/* <p className="description col-12">
                    {selected_item_detail?.description}
                  </p> */}
                </div>
              )}
            </Motion>

            <div
              className={`item-details ${
                hideHeader === true ? "top-scroll" : ""
              }`}
              id={"item-details-container"}
              onScroll={scrolledElement}
              {...handlers}
            >
              <div className="col-12 product-details">
                {!hideHeader && (
                  <h5 className="name col-9">
                    {" "}
                    {selected_item_detail?.menu_item_name}{" "}
                  </h5>
                )}
                <span className="price col-3">
                  {" "}
                  {/* {!isContainDiscountArr && (
                    <>
                      <small className="currency">{`${
                        table_detail?.currency ?? ""
                      }`}</small>
                      {`${
                        selected_item_detail?.dinein_price?.DineIn
                          ? parseFloat(
                              selected_item_detail?.dinein_price?.DineIn
                            ).toFixed(2)
                          : "0.00"
                      }`}
                    </>
                  )} */}
                </span>
                <p className="description">
                  {selected_item_detail?.description}
                </p>
                {/* <div className="labels">
                  {getItemFilterTags(selected_item_detail)}
                </div> */}
                {/* {isContainDiscountArr && getItemDiscountModifiers()} */}
                {/* {cartItemKey > -1 && (
                  <div className="options">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          name="select_options"
                          id="default_options"
                          className="check"
                          onClick={() => {
                            setCurrentItemQyt(1);
                            setTotalPrice(parseFloat(selectedItemPrice));
                            setSelectedOption(null);
                            setModifierArray([]);
                            setSelectedBundleItem(setBundleItems());
                          }}
                          checked={selectedOption === null ? true : false}
                        />
                        <label htmlFor="default_options">
                          1 x {selected_item_detail?.menu_item_name}
                        </label>
                        <span className="price">
                          {`${table_detail?.currency ?? ""}`}
                          {selectedItemPrice}
                        </span>
                      </li>
                      {cart_detail
                        .filter((item) => item.id == selected_item_detail?.id)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="radio"
                                name="select_options"
                                id={`options_${index}`}
                                className="check"
                                onClick={() => {
                                  setCurrentItemQyt(item?.quantity);
                                  setTotalPrice(parseFloat(item?.total));
                                  item.index = index;
                                  setModifierArray(item.modifierArray);
                                  setSelectedBundleItem(
                                    item.selectedBundleItem
                                  );
                                  setSelectedOption(item);
                                }}
                                checked={
                                  selectedOption !== null &&
                                  selectedOption?.index == item.index
                                }
                              />
                              <label htmlFor={`options_${index}`}>
                                {item.quantity} x {item.name}
                              </label>
                              <span className="price">
                                {`${table_detail?.currency ?? ""}`}
                                {parseFloat(item.total).toFixed(2)}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )} */}
              </div>
              {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                !showModifiers &&
                getBundleItems()}
              {selected_item_detail?.StandardModifier !== undefined &&
                selected_item_detail?.StandardModifier.length > 0 &&
                !showModifiers && (
                  <Fragment>
                    <h5 className="sm-title"> Standard Modifiers</h5>
                    <div className="selectors">
                      {getItemStandardModifiers()}
                    </div>
                  </Fragment>
                )}
              {selected_item_detail?.BundledOptionSeries?.length > 0 &&
                showModifiers &&
                getItemModifiers()}
            </div>
          </section>

          <section className={`bottom-sec ${showModifiers ? "flex_btns" : ""}`}>
            <div className="adding-items">
              <div className="items-sec">
                <button className="remove-btn" onClick={decreaseItem}>
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={minusIcon}
                    width="14"
                  />{" "}
                </button>
                <input
                  ref={qytEditInputRef}
                  type="number"
                  step={1}
                  max={999}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onBlur={() => {
                    if (isNaN(currentItemQyt) || currentItemQyt <= 0) {
                      editItemQyt(1);
                    }
                    setCurrentItemQytEdit(false);
                  }}
                  onChange={(e) => {
                    const qytVal = parseInt(
                      String(e.target.value ?? 1).slice(0, 3)
                    );
                    editItemQyt(qytVal);
                  }}
                  className="items-num"
                  value={currentItemQyt}
                  style={{ display: currentItemQytEdit ? "block" : "none" }}
                />
                <input
                  type="text"
                  className="items-num"
                  value={currentItemQyt}
                  onClick={() => {
                    setCurrentItemQytEdit(true);
                  }}
                  readOnly
                  style={{ display: currentItemQytEdit ? "none" : "block" }}
                />
                <button className="add-btn" onClick={increaseItem}>
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={plusIcon}
                    width="14"
                  />{" "}
                </button>
              </div>
              <div className="price-sec">
                <strong
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                >
                  {" "}
                  <small> {`${outletDetails?.currency_symbol ?? ""}`}</small>
                  {parseFloat(totalPrice).toFixed(2)}
                </strong>
                <p> item total </p>
              </div>
            </div>
            <div className={`btns ${showModifiers ? "duo" : ""}`}>
              {showModifiers && (
                <button
                  style={{
                    color: outletDetails?.settings?.brand_main_color,
                    borderColor: outletDetails?.settings?.brand_main_color,
                  }}
                  className="back_btn green-border-btn"
                  onClick={() => setShowModifiers(false)}
                >
                  Back{" "}
                </button>
              )}
              <button
                style={{
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                }}
                className="add_btn green-btn"
                onClick={() => {
                  if (!isNaN(currentItemQyt)) AddToCartHandler();
                }}
              >
                {" "}
                Save to Basket{" "}
              </button>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{selected_item_detail !== null && getAddItemDetail()}</>;
}
const mapStateToProps = (state) => ({
  selected_item_detail: state.storeReducer.selectedItem,
  edited_item_detail: state.storeReducer.editItemDetails,
  table_detail: state.storeReducer.storeDetails,
  menuItems: state.storeReducer.menuItems,
  // filters: state.storeReducer.filters,
  // filter_tag_detail: state.storeReducer.filter_tag_detail,
  cart_detail: state.cartReducer.cartItems,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  addItemCartDetail: actions.addCartItem,
  updateItemCartDetail: actions.updateCartItem,
  removeItemCartDetail: actions.removeCartItem,
  replaceItemCartDetail: actions.replaceCartItem,
  editItemCartDetails: actions.editItemDetails,
  // getTableDetailByFilter: getTableDetailByFilter,
  // showNotificationWithTimeout: showNotificationWithTimeout,
  // emptyEditedItemDetail: emptyEditedItemDetail,
};

EditBundleItem = connect(mapStateToProps, mapDispatchToProps)(EditBundleItem);
export default EditBundleItem;
