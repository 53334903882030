import React, { useRef, useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import {
  checkItemExistInCart,
  checkNegNum,
  getModifierActionSign,
  getModifierTypeName,
  getServerImgUrl,
  getUniTimeString,
  groupByArray,
  imageExists,
  makeNegToPosNum,
} from "../../helpers";
import { actions } from "../../Redux";
import { StyledSVG } from "../../Utils/style";
import { plusIcon, minusIcon } from "../../Assets";
import ReactTooltip from "react-tooltip";

function AddItem({ prevTab, nextTab, ...props }) {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedItem: selected_item_detail,
    editItemDetails: edited_item_detail,
    outletDetails,
    menuItems,
  } = useSelector((state) => state.storeReducer);
  // console.log(
  //   selected_item_detail?.menu_item_name === "bundled",
  //   selected_item_detail?.menu_item_type,
  //   "abababababab"
  // );

  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty(
      "--store-color",
      `${outletDetails?.settings?.brand_main_color}`
    );
  }, [
    outletDetails?.settings?.brand_highlight_color,
    outletDetails?.settings?.brand_main_color,
  ]);

  const { cartItems } = useSelector((state) => state.cartReducer);
  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";

  const { storeDetails } = useSelector((state) => state.storeReducer);

  const arr = window.location.href.split("/");

  // useEffect(() => {
  //   if (selected_item_detail === null) {
  //     navigate(`/dashboard/${arr[arr.length - 1]}`);
  //   }
  // }, []);

  // );
  // const selected_item_detail = useSelector(
  //   (state) => state.tableDetailReducer?.selected_item_detail
  // );
  // const filters = useSelector((state) => state.tableDetailReducer?.filters);
  // const filter_tag_detail = useSelector(
  //   (state) => state.tableDetailReducer?.filter_tag_detail
  // );
  // const cart_detail = useSelector(
  //   (state) => state.cartDetailReducer?.cart_detail
  // );

  const filter_tag_detail = null;
  const cart_detail = null;

  const isContainDiscountArr =
    selected_item_detail?.PosMenuitemsModifier &&
    selected_item_detail?.PosMenuitemsModifier.findIndex(
      (p) => p.action == "discount"
    ) > -1
      ? true
      : false;
  const serviceChargePer = parseFloat(menuItems?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(menuItems?.SalesTaxRate ?? 0);

  const [wrapperClass, setWrapperClass] = useState("");
  const cartItemKey =
    cart_detail && cart_detail.length > 0 && selected_item_detail?.id
      ? cart_detail.findIndex((p) => p.id == selected_item_detail?.id)
      : -1;
  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.menu_price?.item_price
            ? selected_item_detail?.menu_price?.item_price
            : 0
        );

  const [currentItemQyt, setCurrentItemQyt] = useState(
    // edited_item_detail?.quantity != undefined ||
    //   edited_item_detail?.quantity > 0
    //   ? edited_item_detail?.quantity
    //   : 1
    1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;

  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [hideHeader, setHideHeader] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);
  const [modifierChanged, setModifierChanged] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modifierArray, setModifierArray] = useState([]);
  const [comment, setComment] = useState(edited_item_detail?.comment ?? "");
  const [imageArr, setImageArr] = useState([]);
  const imageArrRef = useRef(imageArr);
  imageArrRef.current = imageArr;

  useEffect(() => {
    document.body.classList.add("white");
    getPreModifiers();
  }, []);

  useEffect(() => {
    calTotalPrice();
  }, [selectedItemPrice, modifierChanged]);

  const getPreModifiers = () => {
    let res = [];
    if (
      edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
    ) {
      res = edited_item_detail?.modifierArray;
    } else if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier?.length > 0 &&
      selected_item_detail?.recomItem?.modifier_ids &&
      selected_item_detail?.recomItem?.modifier_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.modifier_ids?.map((modItemId) => {
        const modIndex = selected_item_detail?.PosMenuitemsModifier?.findIndex(
          (p) => p.id == modItemId
        );
        if (modIndex > -1) {
          const modItem =
            selected_item_detail?.PosMenuitemsModifier?.[modIndex];
          const modifierType = modItem?.type ?? "";
          const modifierAction = modItem?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, modItem);
          const unitPrice =
            modItem?.unit_price &&
            modItem?.unit_price !== null &&
            modItem?.unit_price !== ""
              ? parseFloat(modItem?.unit_price)
              : 0;
          res.push({
            id: modItem?.id,
            modifier_id: modItem?.id,
            modifier_group_id: modItem?.modifier_group_id,
            action: modifierAction,
            name: modifierName,
            unit_price: parseFloat(unitPrice),
            modifier_price: parseFloat(unitPrice),
            modifier_quantity: 1,
          });
        }
      });
    }
    if (res.length > 0) {
      setModifierArray(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  };

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
  }

  function getImages(images) {
    let arr = images?.map(async (img) => {
      if (img?.file_name) {
        let a = await imageExists(getServerImgUrl(img?.file_name));
        return a;
      }
    });
    Promise.all(arr)
      .then((resp) => {
        if (resp && resp?.length > 0) {
          setImageArr(resp);
        } else {
          setHideImages(true);
          setHideHeader(true);
          setWrapperClass("scroll-active");
        }
      })
      .catch((er) => {
        setHideImages(true);
        setHideHeader(true);
        setWrapperClass("scroll-active");
      });
  }

  useEffect(() => {
    getImages(
      selected_item_detail?.content?.length > 0
        ? selected_item_detail?.content
        : selected_item_detail?.image
        ? [{ file_name: selected_item_detail?.image }]
        : []
    );

    return () => {
      dispatch(
        actions.editItemDetails({
          editItemDetails: null,
        })
      );
    };
  }, []);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        hideImages == false &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .sort(function (a, b) {
          return parseInt(a?.is_sort ?? 0) - parseInt(b?.is_sort ?? 0);
        })
        .filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        )
        // .filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // )
        .map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          if (
            modifierType === "ingredient" ||
            modifierType === "recipe" ||
            modifierAction === "add" ||
            modifierAction === "remove" ||
            modifierAction === "swap" ||
            modifierAction === "size"
          ) {
            const modifierName = getModifierTypeName(modifierType, element);
            return (
              <div className="options" key={index}>
                <input
                  id={`modifier_${element?.id}`}
                  type="checkbox"
                  className="check"
                  checked={
                    modifierArray.findIndex((p) => p.id == element?.id) > -1
                  }
                  onChange={(el) => {
                    if (el?.target?.checked) {
                      const unitPrice =
                        element?.unit_price &&
                        element?.unit_price !== null &&
                        element?.unit_price !== ""
                          ? parseFloat(element?.unit_price)
                          : 0;

                      const elArr = {
                        id: element?.id,
                        modifier_id: element?.id,
                        modifier_group_id: element?.modifier_group_id,
                        action: modifierAction,
                        name: modifierName,
                        unit_price: parseFloat(unitPrice),
                        modifier_price: parseFloat(unitPrice),
                        modifier_quantity: 1,
                      };
                      const existIndex = modifierArray.findIndex(
                        (p) => p.action == modifierAction
                      );
                      if (existIndex > -1 && modifierAction == "size") {
                        setModifierArray([
                          ...modifierArray.slice(0, existIndex),
                          elArr,
                          ...modifierArray.slice(existIndex + 1),
                        ]);
                      } else {
                        setModifierArray([...modifierArray, elArr]);
                      }
                      setModifierChanged(!modifierChanged);
                    } else if (
                      modifierArray.findIndex((p) => p.id == element?.id) > -1
                    ) {
                      setModifierArray(
                        modifierArray.filter(
                          (modifier) => modifier.id != element?.id
                        )
                      );
                      setModifierChanged(!modifierChanged);
                    }
                  }}
                />
                <label
                  className="modifierLabel"
                  htmlFor={`modifier_${element?.id}`}
                >
                  {" "}
                  {/* {getModifierActionName(modifierAction)}{" "} */}
                  {getModifierTypeName(modifierType, element)}
                </label>

                {element?.unit_price !== null &&
                  element?.unit_price !== "" &&
                  (element?.unit_price > 0 || element?.action == "size") && (
                    <span className="price-sm">
                      {" "}
                      {getModifierActionSign(modifierAction)}{" "}
                      {checkNegNum(element.unit_price) ? "- " : ""}
                      {`${currencySymbol ?? ""}`}
                      {parseFloat(
                        checkNegNum(element.unit_price)
                          ? makeNegToPosNum(element.unit_price)
                          : element.unit_price
                      ).toFixed(2)}
                    </span>
                  )}
              </div>
            );
          }
        });
    }
    return res;
  }

  function getItemModifiers(action = "") {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        ),
        // selected_item_detail?.PosMenuitemsModifier.filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title pl-1">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>
                {getItemModifiersItems(group, action)}
              </>
            );
        });
      }
    }
    return res;
  }

  function getItemDiscountModifiers() {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const discountModifiersArr =
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) => p.action == "discount"
        ).map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <li>
              <input
                type="radio"
                name="select_options"
                id={`modifier_${element?.id}`}
                className="check"
                onClick={() => {
                  const discountEl = {
                    id: element?.id,
                    modifier_id: element?.id,
                    modifier_group_id: element?.modifier_group_id,
                    action: modifierAction,
                    name: modifierName,
                    unit_price: parseFloat(unitPrice),
                    modifier_price: parseFloat(unitPrice),
                    modifier_quantity: element?.amount_per_item,
                  };
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == "discount"
                  );
                  if (existIndex > -1) {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      discountEl,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, discountEl]);
                  }
                  setSelectedItemPrice(parseFloat(unitPrice));
                }}
                checked={
                  modifierArray.findIndex((p) => p.id == element?.id) > -1
                }
              />
              <label htmlFor={`modifier_${element?.id}`}>
                {element?.amount_per_item} x{" "}
                {selected_item_detail?.menu_item_name}
              </label>
              <span className="price">
                {`${currency ?? ""}`}
                {parseFloat(unitPrice).toFixed(2)}
              </span>
            </li>
          );
        });
      res = (
        <div className="options">
          <ul>
            <li>
              <input
                type="radio"
                name="select_options"
                id={`default_options`}
                className="check"
                onClick={() => {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.action != "discount"
                    )
                  );
                  setSelectedItemPrice(defaultSelectedItemPrice);
                }}
                checked={
                  modifierArray.findIndex((p) => p.action == "discount") < 0
                }
              />
              <label htmlFor="default_options">
                1 x {selected_item_detail?.menu_item_name}
              </label>
              <span className="price">
                {`${currency ?? ""}`}
                {parseFloat(defaultSelectedItemPrice).toFixed(2)}
              </span>
            </li>
            {discountModifiersArr}
          </ul>
        </div>
      );
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title pl-1" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
              const modifierId = element?.id ? `SM-${element?.id}` : "";
              const modifierType = element?.type ?? "";
              const modifierAction = stanEl?.ModifierClass?.name ?? "";
              const modifierName = element?.name ?? "";
              return (
                <div className="options" key={index}>
                  <input
                    id={`standard_modifier_${modifierId}`}
                    type="checkbox"
                    className="check"
                    checked={
                      modifierArray.findIndex((p) => p.id == modifierId) > -1
                    }
                    onChange={(el) => {
                      if (el?.target?.checked) {
                        const addEl = {
                          id: modifierId,
                          modifier_id: modifierId,
                          action: modifierAction,
                          name: modifierName,
                          unit_price: 0,
                          modifier_price: 0,
                          modifier_quantity: 1,
                        };
                        const existIndex = modifierArray.findIndex(
                          (p) => p.action == modifierAction
                        );
                        if (existIndex > -1) {
                          setModifierArray([
                            ...modifierArray.slice(0, existIndex),
                            addEl,
                            ...modifierArray.slice(existIndex + 1),
                          ]);
                        } else {
                          setModifierArray([...modifierArray, addEl]);
                        }
                      } else if (
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      ) {
                        setModifierArray(
                          modifierArray.filter(
                            (modifier) => modifier.id != modifierId
                          )
                        );
                      }
                    }}
                  />
                  <label
                    className="modifierLabel"
                    htmlFor={`standard_modifier_${modifierId}`}
                  >
                    {" "}
                    {/* {getModifierActionName(modifierAction)}{" "} */}
                    {modifierName}
                  </label>
                </div>
              );
            })}
          </>
        );
      });
    }
    return res;
  }

  function getItemTags() {
    let res = null;
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = <span className="tags">Popular</span>;
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = <span className="tags blue">Trending</span>;
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            const fullFilterName =
              filterTag?.name && filterTag?.name !== "" ? filterTag?.name : "";
            return (
              <li
                style={style}
                className={className}
                key={index}
                data-tip={`${fullFilterName}`}
              >
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  function AddToCartHandler() {
    if (selected_item_detail?.id) {
      const modifierAddPrice = modifierArray.length
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
      const modifierRemovePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "remove")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierSizePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "size")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifersPrice =
        modifierAddPrice - modifierRemovePrice + modifierSizePrice;
      let actualItemPrice = selectedItemPrice;
      if (selected_item_detail?.promotions) {
        // total amount changes with percentage discount
        if (
          modifersPrice !== 0 &&
          selected_item_detail?.promotions.type == "Percentage"
        ) {
          let discountPrice =
            ((selected_item_detail?.promotions?.acutalPrice + modifersPrice) *
              parseFloat(selected_item_detail?.promotions.amount)) /
            100;
          actualItemPrice =
            selected_item_detail?.promotions.acutalPrice - discountPrice >= 0
              ? selected_item_detail?.promotions.acutalPrice - discountPrice
              : 0;
        }
      }
      const singlePriceWithModifier = actualItemPrice + modifersPrice;
      const priceWithModifier = singlePriceWithModifier * currentItemQyt;

      // const serviceChargeAmount =
      //   selected_item_detail?.menu_price?.service_charge == "1"
      //     ? parseFloat(
      //         (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
      //       )
      //     : 0;
      const serviceChargeAmount = 0;
      const salesTaxAmount =
        selected_item_detail?.menu_price?.sales_tax == "1"
          ? parseFloat(
              ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;

      let item = {
        bundleName:
          selected_item_detail?.menu_item_type === "bundled"
            ? selected_item_detail?.menu_item_type
            : "",
        bundlePosName:
          selected_item_detail?.menu_item_type === "bundled"
            ? selected_item_detail?.pos_name
            : "",
        id: selected_item_detail?.id,
        offline_id: `${getUniTimeString()}`,
        is_fired: false,
        is_hold: false,
        name: selected_item_detail?.pos_name,
        pos_name: selected_item_detail?.menu_item_name,
        menu_class_id: selected_item_detail?.menu_class_id,
        menu_class_name: selected_item_detail?.menu_class_name,
        image: selected_item_detail?.image,
        is_added: false,
        is_service_charge:
          selected_item_detail?.menu_price?.service_charge == "1" ||
          selected_item_detail?.menu_price?.service_charge == true
            ? true
            : false,
        is_sales_tax:
          selected_item_detail?.menu_price?.sales_tax == "1" ||
          selected_item_detail?.menu_price?.sales_tax == true
            ? true
            : false,
        quantity: currentItemQyt,
        amount: singlePriceWithModifier,
        amount_without_modifiers: parseFloat(actualItemPrice),
        item_amount: actualItemPrice,
        actual_menu_price: parseFloat(
          selected_item_detail?.menu_price?.item_price ?? 0
        ),
        comment: comment,
        total: priceWithModifier,
        menu_item_type: selected_item_detail?.menu_item_type ?? "",
        menu_price: selected_item_detail?.menu_price ?? "",
        service_charge_amount: serviceChargeAmount,
        sales_tax_amount: salesTaxAmount,
        catID: selected_item_detail?.other_category_id ?? "",
        sub_category_id: selected_item_detail?.sub_category_id ?? "",
        sub_class_id: selected_item_detail?.sub_class_id ?? "",
        modifierArray: modifierArray.sort(function (a, b) {
          return a.id - b.id;
        }),
      };
      if (selected_item_detail?.promotions) {
        const promoObj = selected_item_detail?.promotions;
        if (modifersPrice !== 0 && promoObj.type == "Percentage") {
          let discountPrice =
            ((promoObj.acutalPrice + modifersPrice) *
              parseFloat(promoObj.amount)) /
            100;
          promoObj.promotionTotaldiscount =
            parseFloat(discountPrice) * currentItemQyt;
          promoObj.discountPerItem = discountPrice;
        }
        if (promoObj.acutalPrice < promoObj.discountPerItem) {
          promoObj.discountPerItem = promoObj.acutalPrice;
          promoObj.promotionTotaldiscount =
            parseFloat(promoObj.acutalPrice) * currentItemQyt;
        }
        item.promotions = [promoObj];
      }

      // console.log('menuItems',menuItems)

      // if(menuItems?.promotion){

      // }

      const checkItemExistInCartKey = checkItemExistInCart(item, cartItems);

      // if (checkItemExistInCartKey === -1) {
      //   dispatch(actions.addCartItem({ item }))
      // }
      // else {
      //   dispatch(actions.updateCartItem({ index: checkItemExistInCartKey, item }))
      // }

      if (
        (edited_item_detail !== null &&
          edited_item_detail?.offline_id !== undefined) ||
        checkItemExistInCartKey > -1
      ) {
        const existOffId =
          checkItemExistInCartKey > -1
            ? checkItemExistInCartKey
            : cart_detail?.findIndex(
                (p) => p.offline_id == edited_item_detail.offline_id
              );

        if (existOffId > -1) {
          dispatch(actions.updateCartItem({ index: existOffId, item }));
        } else {
          dispatch(actions.addCartItem({ item }));
        }
      } else {
        dispatch(actions.addCartItem({ item }));
      }

      if (props?.isLaptopScreen) {
        props.toggleAddItemPopUp(false);
      } else {
        navigate(-1);
      }

      // dispatch(
      //   getTableDetailByFilter(
      //     outletDetails?.PosTableMaster?.qr_code_id,
      //     filters,
      //     ""
      //   )
      // );
    }
    return;
  }

  // function getBundledItem() {
  //   const bundledArr = selected_item_detail?.BundledOptionSeries;
  //   // const ans = [<h5 class="sm-title"> Modifier </h5>];
  //   console.log(bundledArr, "zzzz");
  //   const ans = bundledArr.map((bundle) => {
  //     return (
  //       <div>
  //         <h5 class="sm-title"> {bundle.name} </h5>
  //         {bundle?.BundledMenuItem?.map((item) => {
  //           console.log("llll", item);
  //           return (
  //             <div>
  //               <div class="selectors">
  //                 <div class="options">
  //                   <input
  //                     id={item.option_series_id}
  //                     name="bundle_211"
  //                     type="checkbox"
  //                     class="check"
  //                   />
  //                   <label for="bundle_0">{item?.bundle_menu_item_name}</label>
  //                   <span class="price-sm">{item?.modifier_price}</span>
  //                 </div>
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   });

  //   return ans;
  // }

  return (
    <>
      {selected_item_detail !== null && (
        <div className={"wrapper new-wrapper " + wrapperClass}>
          <ReactTooltip effect="solid" />
          <section className="add-items_sec">
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 270 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className="image-container-sec"
                  style={{
                    height: `${x}px`,
                  }}
                >
                  <div
                    onClick={() => {
                      if (props?.isLaptopScreen) {
                        props.toggleAddItemPopUp(false);
                      } else {
                        navigate(-1);
                      }
                    }}
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <div className="image-sec">
                    <ImageSlider
                      images={imageArr}
                      onOpen={(index) => {
                        setShowImageView(true);
                        setCurrentImageIndex(index);
                      }}
                      // isOpen={true}
                    />
                    {showImageView === true && (
                      <ImageViewer
                        images={imageArr}
                        isOpen={showImageView}
                        currentIndex={currentImageIndex}
                        onClose={() => setShowImageView(false)}
                      />
                    )}
                  </div>
                </div>
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 0 }}
              style={{
                x: spring(hideHeader === false ? 0 : 100, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className={`foobar-header row mx-0 ${
                    hideHeader === true ? "touch-top" : ""
                  }`}
                  style={{
                    height: `${x}%`,
                  }}
                >
                  <div
                    onClick={() => {
                      if (props?.isLaptopScreen) {
                        props.toggleAddItemPopUp(false);
                      } else {
                        navigate(-1);
                      }
                    }}
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <h5 className="name col-9">
                    {selected_item_detail?.menu_item_name}
                  </h5>
                  <span className="price col-3 px-0 text-right h5">
                    {" "}
                    {!isContainDiscountArr && (
                      <>
                        <small className="currency">{`${
                          currencySymbol ?? ""
                        }`}</small>
                        {`${
                          selected_item_detail?.menu_price?.item_price
                            ? parseFloat(
                                selected_item_detail?.menu_price?.item_price
                              ).toFixed(2)
                            : "0.00"
                        }`}
                      </>
                    )}
                  </span>
                </div>
              )}
            </Motion>
            <div
              className={`item-details ${
                hideHeader === true ? "top-scroll" : ""
              }`}
              onScroll={scrolledElement}
              {...handlers}
            >
              <div className="col-12 product-details">
                {!hideHeader && (
                  <h5 className="name col-9">
                    {" "}
                    {selected_item_detail?.menu_item_name}{" "}
                  </h5>
                )}
                <span className="price col-3">
                  {" "}
                  {!isContainDiscountArr && (
                    <>
                      <small className="currency">{`${
                        currencySymbol ?? ""
                      }`}</small>
                      {`${
                        selected_item_detail?.menu_price?.item_price
                          ? parseFloat(
                              selected_item_detail?.menu_price?.item_price
                            ).toFixed(2)
                          : "0.00"
                      }`}
                    </>
                  )}
                </span>
                <p className="description">
                  {selected_item_detail?.description}
                </p>
                <div className="labels">
                  {getItemFilterTags(selected_item_detail)}
                </div>
                {isContainDiscountArr && getItemDiscountModifiers()}
              </div>
              {selected_item_detail?.PosMenuitemsModifier &&
                selected_item_detail?.PosMenuitemsModifier.length > 0 && (
                  <>
                    <h5 className="sm-title"> Modifiers </h5>

                    <div className="selectors">
                      {/* {selected_item_detail?.PosMenuitemsModifier.findIndex(
                        (p) => p.action == "size"
                      ) > -1 && <>{getItemModifiers("size")}</>} */}
                      {getItemModifiers("")}
                      {getItemStandardModifiers()}
                      <div className="message_col p-0 mt-2">
                        <label>Notes:</label>
                        <textarea
                          id={`comment_${selected_item_detail?.id}`}
                          name={`comment_${selected_item_detail?.id}`}
                          className={"form-control"}
                          placeholder="Add note here."
                          type="text"
                          value={comment}
                          onChange={(el) => {
                            setComment(el.target.value);
                          }}
                        >
                          {comment}
                        </textarea>
                      </div>
                    </div>
                  </>
                )}
              {selected_item_detail?.menu_item_type === "bundled" &&
                selected_item_detail?.BundledOptionSeries.length > 0}
            </div>
          </section>

          <section className="bottom-sec maxBtn" id="new-MaxBtn">
            <div className="adding-items">
              <div className="items-sec">
                <button className="remove-btn" onClick={decreaseItem}>
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={minusIcon}
                    width="14"
                  />{" "}
                </button>
                <input
                  type="text"
                  className="items-num"
                  value={currentItemQyt}
                  readOnly
                />
                <button className="add-btn" onClick={increaseItem}>
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={plusIcon}
                    width="14"
                  />{" "}
                </button>
              </div>
              <div className="price-sec">
                <strong
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                >
                  {" "}
                  <small
                    style={{
                      color: outletDetails?.settings?.brand_highlight_color,
                    }}
                  >
                    {" "}
                    {`${currencySymbol ?? ""}`}
                  </small>
                  {totalPrice.toFixed(2)}
                </strong>
                <p> item total </p>
              </div>
            </div>
            <button
              className="green-btn "
              style={{
                backgroundColor: outletDetails?.settings?.brand_main_color,
              }}
              onClick={() => {
                AddToCartHandler();
                // history.push("/cart")
              }}
            >
              {" "}
              Add to Basket{" "}
            </button>
          </section>
        </div>
      )}
    </>
  );
}
export default AddItem;
