import * as actionTypes from "../actionTypes";

export const getMenuItems = (payload) => ({
  type: actionTypes.GET_MENU_ITEMS_REQUESTED,
  payload,
});

export const getOutletDetails = ({ outlet_id }) => {
  let query = "";
  if (outlet_id?.trim()) {
    query += `?outlet_id=${outlet_id?.trim()}`;
  }
  return {
    type: actionTypes.GET_OUTLET_DETAILS_REQUESTED,
    query,
  };
};

export const getStoreDetails = ({ storeId }) => {
  let query = "";
  if (storeId?.trim()) {
    query += `?url_prefix=${storeId?.trim()}`;
  }
  return {
    type: actionTypes.GET_STORE_DETAILS_REQUESTED,
    query,
  };
};

export const selectedItem = (payload) => ({
  type: actionTypes.SELECTED_ITEM,
  payload,
});

export const editItemDetails = (payload) => ({
  type: actionTypes.EDIT_ITEM_DETAILS,
  payload,
});

export const updatePromotionList = (payload) => ({
  type: actionTypes.UPDATE_PROMOTION_LIST,
  payload,
});
export const updateSearchText = (input) => ({
  type: actionTypes.ADD_SEARCH_TEXT,
  input,
});
