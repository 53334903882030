import React, { useCallback, useEffect, useState } from "react";
import "../../CSS/payment.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import StepperProgress from "../Components/StepperProgress";
import {
  calendarSmall,
  crossIcon,
  locationBigIcon,
  visaIcon,
} from "../../Assets";
import SelectInput from "../Components/SelectInput";
import { Button, InputAdornment, MenuItem, Select } from "@mui/material";
import FloatingInput from "../Components/FloatingInput";
import PopupModal from "../Components/PopupModal";
import FloatingGroupInput from "../Components/FloatingGroupInput";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { actions } from "../../Redux";
import { StyledSVG } from "../../Utils/style";
import { countryList } from "../../Utils/constants";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { Stack } from "@mui/material";
import { store, START_LOADING, STOP_LOADING } from "../../Redux";
import { Terms } from "../Components/Terms";
import { checkNull, parseRound } from "../../helpers";
import moment from "moment";
import Cart from "./Cart";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const CheckoutForm = ({ isLaptopScreen = false }) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  // updated quotation modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { finalCart, cartBasicDetails, orderDetails, orderPlaced } =
    useSelector((state) => state.cartReducer);
  const { outletDetails, storeDetails } = useSelector(
    (state) => state.storeReducer
  );
  const {
    selectedLocation,
    riderNote,
    deliveryTime,
    deliveryDate,
    deliveryType,
  } = useSelector((state) => state.deliveryOptionsReducer);
  const { endTime, quotationId } = useSelector(
    (state) => state.quotationReducer
  );

  const { paymentTokenData, paymentTokengenerated, paymentIntent } =
    useSelector((state) => state.paymentReducer);

  const pageLoading = useSelector((state) => state?.loaderReducer?.loading);

  const currency = outletDetails?.currency ? outletDetails?.currency + "" : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [newPayment, setNewPayment] = useState("");
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#333",
        fontFamily: "'Maven Pro', sans-serif",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap",
        // fontSmoothing: "antialiased",
        fontWeight: "400",
        fontSize: "13px",
        "::placeholder": {
          color: "#a5afb5",
        },
      },
      invalid: {
        color: "red",
        iconColor: "red",
      },
    },
  };

  useEffect(() => {
    if (elements) {
      const cardNumberElement =
        elements.getElement("cardNumber") ||
        elements?.create("cardNumber", {
          style: CARD_ELEMENT_OPTIONS.style,
          placeholder: "Card Number",
          showIcon: true,
        });

      const cardExpiryElement =
        elements.getElement("cardExpiry") ||
        elements?.create("cardExpiry", {
          style: CARD_ELEMENT_OPTIONS.style,
          placeholder: "Expiry",
        });

      const cardCvvElement =
        elements.getElement("cardCvc") ||
        elements?.create("cardCvc", {
          style: CARD_ELEMENT_OPTIONS.style,
          placeholder: "CVC",
        });

      cardNumberElement?.mount("#card-number-element");
      cardExpiryElement?.mount("#card-expiry-element");
      cardCvvElement?.mount("#card-cvc-element");
    }
  }, [elements]);

  useEffect(() => {
    try {
      paymentIntent &&
        orderPlaced === false &&
        stripe
          .confirmCardPayment(paymentIntent?.client_secret, {
            payment_method: paymentIntent?.payment_method,
          })
          .then(function (result, error) {
            if (result.error) {
              return toast.error(result.error.message);
            }
            // Handle result.error or result.paymentIntent
            let cloneCart = {
              ...finalCart,
              quotationId: quotationId ?? "",
              riderNote: riderNote ?? "",
              deliveryTime: deliveryTime ?? "",
              deliveryDate: deliveryDate ?? "",
              deliveryFee: cartBasicDetails?.deliveryFee ?? "",
              updated_delivery_fee:
                cartBasicDetails?.updated_delivery_fee ?? "",
              discount: cartBasicDetails?.totalDiscount ?? "",
            };

            cloneCart.receiver = {
              first_name: document.getElementById("first-name").value,
              last_name: document.getElementById("last-name").value,
              customer_phone: document.getElementById("user-mobile").value,
              country_code: document.getElementById("country-code").innerText,
              customer_email: document.getElementById("user-email").value,
              customer_address: null,
            };

            cloneCart.ordrr_online.payment_intent_id = paymentIntent?.id;
            cloneCart.ordrr_online.payment_status =
              result?.paymentIntent?.status;

            console.log("====================================");
            console.log(
              "cloneCartttt",
              cloneCart,
              quotationId,
              deliveryTime,
              cartBasicDetails?.deliveryFee,
              cartBasicDetails?.totalDiscount
            );
            console.log("====================================");

            dispatch(actions.placeOrder(cloneCart));
          })
          .catch((ex) => console.log("confirmCardPaymentEx", ex));
    } catch (ex) {}
  }, [orderPlaced, paymentIntent]);

  // const validateFields = ['name','cardNumber','expDate','cvc'];

  // const validation = {
  //   name : false,
  //   cardNumber : false,
  //   expDate : false,
  //   cvc : false,
  // }

  // const validate = () =>{

  //   validateFields.forEach(each=>{
  //       if()
  //   })

  // }

  useEffect(() => {
    document.body.classList.add("white");
  }, []);

  const [changeAddressModal, setChangeAddressModal] = useState(false);

  ///////////////////////////////////////
  // pull data from redux for quotation
  const deliveryOptionsReducer = useSelector(
    (state) => state.deliveryOptionsReducer
  );
  const {
    longitude: storeLong,
    latitude: storeLat,
    address: storeAddress,
    address_2: storeAddressTwo,
    city_id: cityId,
    zip_code: zipCode,
  } = outletDetails;
  const deliveryLong = deliveryOptionsReducer?.selectedLocation?.longitude;
  const deliveryLat = deliveryOptionsReducer?.selectedLocation?.latitude;
  const deliveryAddress = deliveryOptionsReducer?.selectedLocation?.address;
  const {
    cartItems,
    cartBasicDetails: { totalAmount },
  } = useSelector((state) => state.cartReducer);

  const deliveryDateTime = `${deliveryDate} ${deliveryTime}`;
  const pickup_buffer_time = parseInt(
    outletDetails?.settings?.pickup_buffer_time ?? 0
  );

  const totalItems = cartItems.reduce((acc, curr) => acc + curr.quantity, 0);

  const checkScheduleTime = moment(deliveryDateTime)
    .subtract(pickup_buffer_time, "minutes");
  const currentMechantTime = moment(new Date())
    .utc()
    .utcOffset(storeDetails?.Merchant?.timezone_offset);

  const test = {
    data: {
      // scheduleAt: currentMechantTime.isBefore(checkScheduleTime)
      //   ? checkScheduleTime.format().slice(0, -6) +
      //     `${storeDetails?.Merchant?.timezone_offset}`
      //   : "",
      serviceType: "MOTORCYCLE",
      // specialRequests: ["PURCHASE_SERVICE_1"],
      language: "en_SG",
      stops: [
        {
          coordinates: {
            lat: `${storeLat}`,
            lng: `${storeLong}`,
          },
          address: `${storeAddress}${checkNull(storeAddressTwo,false)?" "+storeAddressTwo:""} ${cityId} ${zipCode}`,
        },
        {
          coordinates: {
            lat: `${deliveryLat}`,
            lng: `${deliveryLong}`,
          },
          address: `${deliveryAddress}`,
        },
      ],
      item: {
        quantity: `${totalItems}`,
        weight: "LESS_THAN_3KG",
        categories: ["FOOD_DELIVERY"],
        // categories: ["FOOD_DELIVERY", "OFFICE_ITEM"],
        // handlingInstructions: ["KEEP_UPRIGHT", "FRAGILE"],
      },
      isRouteOptimized: true,
      // "cashOnDelivery": {
      //     "amount": "100"
      // }
    },
  };
  if(currentMechantTime.isBefore(checkScheduleTime)){
    test.data.scheduleAt = checkScheduleTime.format().slice(0, -6) +
    `${storeDetails?.Merchant?.timezone_offset}`;
  }

  const handleConfirm = (currentTime) => {
    if (
      outletDetails?.settings?.enable_delivery_partner === "yes" &&
      !(
        outletDetails?.settings?.preferred_delivery_partner === "0" ||
        outletDetails?.settings?.preferred_delivery_partner === null
      )
    )
      dispatch(actions.getQuotations(test));
  };

  function validations(
    first_name,
    last_name,
    customer_phone,
    country_code,
    customer_email,
    customer_holder_name
  ) {
    function validateEmail(email) {
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      console.log(re.test(email), email);
      if (!re.test(email)) {
        toast.error("Incorrect email address");
        return;
      }
      return re.test(email);
    }

    if (!first_name) {
      toast.error("Enter first name");
      return;
    }
    if (!last_name) {
      toast.error("Enter last name");
      return;
    }
    if (!customer_phone) {
      toast.error("Enter phone number");
      return;
    }
    if (!/^[0-9]+$/.test(customer_phone) || customer_phone.length > 10) {
      toast.error("Enter a valid phone number");
      return;
    }
    if (!customer_email) {
      toast.error("Enter email address");
      return;
    }
    console.log(customer_email, "aaaa");
    if (!validateEmail(customer_email)) return;
    if (!customer_holder_name) {
      toast.error("Enter card holder name ");
      return;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    store.dispatch({ type: START_LOADING });

    const first_name = document.getElementById("first-name").value;
    const last_name = document.getElementById("last-name").value;
    const customer_phone = document.getElementById("user-mobile").value;
    const country_code = document.getElementById("country-code").innerText;
    const customer_email = document.getElementById("user-email").value;
    const customer_holder_name =
      document.getElementById("card-holder-name").value;
    console.log({
      first_name,
      last_name,
      customer_phone,
      country_code,
      customer_email,
      customer_holder_name,
    });
    if (
      !validations(
        first_name,
        last_name,
        customer_phone,
        country_code,
        customer_email,
        customer_holder_name
      )
    ) {
      store.dispatch({ type: STOP_LOADING });
      return;
    }

    var options = {
      // address_zip: document.getElementById("postal-code").value,
    };

    let cardElement = await elements.getElement("cardNumber");

    // stripe.customers.

    stripe
      .createToken(cardElement, options)
      .then(setOutcome)
      .catch((ex) => {
        console.log(ex);
        store.dispatch({ type: STOP_LOADING });
      });
  };
  /////////////////////////////////////////////////////////
  const closeChangeAddressModal = useCallback(
    (value) => {
      setChangeAddressModal(value);
    },
    [changeAddressModal]
  );
  const closeTermsModal = useCallback(
    (value) => {
      setShowTermsPopup(value);
    },
    [showTermsPopup]
  );
  useEffect(() => {
    orderPlaced && navigate("/confirm");
  }, [orderPlaced]);

  let collectArr = { 1: "Credit Card", 2: "Debit Card" };
  let deliveryArr = { 1: "42 Jervois", 2: "43 Jervois" };

  async function setOutcome(result) {
    var successElement = document.querySelector(".success");
    var errorElement = document.querySelector(".error");
    successElement.classList.remove("visible");
    errorElement.classList.remove("visible");

    if (result.token) {
      // In this example, we're simply displaying the token
      // successElement.querySelector('.token').textContent = result.token.id;
      // successElement.classList.add('visible');

      // custom code

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: {
          token: result.token.id,
        },
        billing_details: {
          name:
            document.getElementById("first-name").value +
            " " +
            document.getElementById("last-name").value,
          email: document.getElementById("user-email").value,
          phone: document.getElementById("user-mobile").value,
          address: {
            city: selectedLocation?.city ?? null,
            country:
              countryList[selectedLocation?.country || 0]?.country_code ?? null,
            line1: selectedLocation?.street_address ?? null,
            line2: null,
            postal_code: selectedLocation?.zip_code ?? null,
            state: null,
          },
        },
      });

      if (error) {
        toast.error("Something went wrong please try again.");
        return;
      }
      setNewPayment(paymentMethod);

      dispatch(
        actions.paymentIntent({
          payment_method_id: paymentMethod?.id,
          amount: parseRound(cartBasicDetails?.totalAmount || 0, 2, true),
          currency,
          stripe_account_id: outletDetails?.settings?.stripe_account_id ?? "",
          application_fee:
            parseRound(cartBasicDetails?.totalCartAmount || 0, 2, true) *
            ((outletDetails?.settings?.gogmgo_commission || 0) / 100),
          customer: {
            name:
              document.getElementById("first-name").value +
              " " +
              document.getElementById("last-name").value,
            email: document.getElementById("user-email").value,
            mobile_number: document.getElementById("user-mobile").value,
          },
        })
      );

      // In a real integration, you'd submit the form with the token to your backend server
      //var form = document.querySelector('form');
      //form.querySelector('input[name="token"]').setAttribute('value', result.token.id);
      //form.submit();
    } else if (result.error) {
      toast.error(result.error.message);
      console.log(result.error.message);
      store.dispatch({ type: STOP_LOADING });
    }
  }

  const getPaymentInfoDiv = () => (
    <div className="Payment  mb-5 pb-5 mt-pay-4">
      <section className="payment-info w-100">
        <div className="col-12 cd-px-md-4">
          <h1 className="title px-0">Payment Info</h1>
        </div>

        <div className="col-12 px-4 cd-px-md-4">
          {/* <div className="group">
                <div className="selectInput">
                  <SelectInput
                    brand_highlight_color={outletDetails?.settings?.brand_highlight_color}
                    options={collectArr} />
                </div>
              </div> */}

          <input type="hidden" name="token" />

          <div className="group mb-0 cardDetails-sec">
            <label>
              <span className="card-label">CARD HOLDER NAME</span>
              <input
                placeholder="Card Holder Name"
                id="card-holder-name"
                style={{
                  paddingLeft: 0,
                  borderBottom: "1px solid  rgb(221, 221, 221)",
                  width: "100%",
                }}
              />
            </label>
            <label>
              <span className="card-label">CARD NUMBER</span>
              <div id="card-number-element" className="field"></div>
            </label>
            <label>
              <span>EXPIRATION DATE</span>
              <div id="card-expiry-element" className="field"></div>
            </label>
            <label className="mb-8 mb-new-8">
              <span>CVC</span>
              <div id="card-cvc-element" className="field"></div>
            </label>
          </div>
        </div>
      </section>
    </div>
  );

  const getCustomerInfoDiv = () => (
    <div className="Payment mt-pay-4">
      <div className="Payment pb-0">
        <div className="col-12 cd-px-md-4">
          <h1 className="title px-0">Contact Details</h1>
        </div>

        <div className="col-12 px-4 pb-5 cd-px-md-4">
          <div className="group">
            <label>
              <span>FIRST NAME</span>
              <input
                id="first-name"
                name="first-name"
                className="field "
                placeholder="First Name"
              />
            </label>
            <label>
              <span>LAST NAME</span>
              <input
                id="last-name"
                name="last-_name"
                className="field "
                placeholder="Last Name"
              />
            </label>
            <label>
              <span>PHONE NUMBER</span>
              <div
                className="col-3 countrySelect"
                style={{ paddingLeft: 0, borderColor: "#333" }}
              >
                <SelectInput
                  id="country-code"
                  // onClick ={ console.log(document.querySelector("#country-code").innerText) }
                  country={true}
                  options={[{ country_code: "+65" }]}
                  value={0}
                  keyToCheck="country_code"
                />
              </div>
              <input
                id="user-mobile"
                name="user_mobile"
                className="field col-9-md"
                placeholder="Phone Number"
                type="tel"
              />
            </label>
            <label>
              <span>EMAIL</span>
              <input
                id="user-email"
                name="user_email"
                className="field"
                type="email"
                placeholder="Email Address"
              />
            </label>
          </div>
        </div>
      </div>
      <div className=" contact_details w-100">
        <div className="col-12 px-4">
          <form></form>
        </div>
      </div>
    </div>
  );

  const getBotmDiv = () => (
    <div className="bottom-sec py-2 maxBtn btm-btn-sz">
      <div className="group sub-btn d-flex justify-content-between my-2 mx-mobile-4">
        <Button
          // type="submit"
          className="col-12 m-0"
          disabled={!stripe}
          onClick={(e) => {
            const currentTime = new Date().getTime() / 1000;
            if (
              deliveryType.key === "delivery" &&
              Math.floor(currentTime > endTime)
            ) {
              handleConfirm(currentTime);
              handleOpen();
            } else {
              handleSubmit(e);
            }
          }}
          style={{
            backgroundColor: outletDetails?.settings?.brand_main_color,
          }}
        >
          <span className="w-100">
            <span className="float-left pl-5 btm-btn-pl-5">
              {currencySymbol} {parseRound(finalCart?.paid_amount || 0)}
            </span>
            <span className="float-right pr-5 btm-btn-pr-5">CONFIRM & PAY</span>
          </span>
        </Button>
      </div>
      <div className="col-12">
        <p className="my-1 text-center">
          By clicking <b>'CONFIRM & PAY'</b>, I agree and accept to GoGMGo's{" "}
          <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
          <b
            onClick={() =>
              window.open("https://www.gogmgo.com/privacy-policy/", "_newtab")
            }
          >
            Privacy Policy
          </b>
        </p>
      </div>
    </div>
  );

  const getFormContainer = () => {
    if (isLaptopScreen) {
      return (
        <>
          <div className="col-xl-4 col-lg-4 col-md-4 padding-left-0 col-4">{getCustomerInfoDiv()}</div>
          <div className="col-xl-4 col-lg-4 col-md-4 padding-left-0 col-4">{getPaymentInfoDiv()}</div>

          <div className="col-xl-4 col-lg-4 col-md-4 padding-left-0 col-4 padding-right-0 i-pr-00">
            {isLaptopScreen && (
              <>
                <div className={`width-30 new-delivery-btn`}>
                  <div className="white-bg">
                    <Cart isLaptopScreen={isLaptopScreen} hideCartBtn={true} />
                    {getBotmDiv()}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          {getCustomerInfoDiv()}
          {getPaymentInfoDiv()}
          {getBotmDiv()}
        </>
      );
    }
  };

  return (
    <>
      <PopupModal
        showDrawer={changeAddressModal}
        toggleDrawer={closeChangeAddressModal}
      >
        <>
          <div className="rider-note-modal">
            <div className="col-12 cross-btn-con">
              <figure
                className="float-right"
                onClick={() => closeChangeAddressModal(false)}
              >
                <StyledSVG
                  color={outletDetails?.settings?.brand_highlight_color}
                  src={crossIcon}
                  width="40"
                />
              </figure>
            </div>
            <section className="login-section OtpVerifyMs row mx-0">
              <div className="sm-content col-12 px-5">
                <figure>
                  <img src={locationBigIcon} alt="" />
                </figure>
                <h2 className="mb-4">Update Delivery Details </h2>
                <p>
                  Please provide us your{" "}
                  <b> preferred address for this delivery.</b>
                </p>
              </div>
              <div className="col-12 mt-5 px-5">
                <div className="selectInput mx-5">
                  <SelectInput defaultValue="1" options={deliveryArr} />
                </div>
              </div>
              <div className="col-12 mt-5 px-5">
                <div className="text-align-left">
                  <span
                    className="anchor_link"
                    onClick={() => navigate("delivery")}
                  >
                    ADD NEW ADDRESS
                  </span>
                </div>
              </div>
              <div className="col-12 mt-0 px-4">
                <form id="payment-form">
                  <div id="card-element"></div>
                  <div id="card-errors" role="alert"></div>

                  <div className="group mt-5">
                    <FloatingInput label="STREET ADDRESS" />
                  </div>
                  <div className="group">
                    <FloatingInput label="UNIT NUMBER" />
                  </div>

                  <div className="group row d-flex">
                    <FloatingGroupInput
                      fullWidth
                      variant="standard"
                      className="mt-0"
                    >
                      <InputLabel>COUNTRY & CITY</InputLabel>
                      <div className="row mx-0 mt-16">
                        <div className="col-3 selectBox">
                          <Input autoComplete="new-password" fullWidth />
                        </div>
                        <div className="col-9">
                          <Input autoComplete="new-password" fullWidth />
                        </div>
                      </div>
                    </FloatingGroupInput>
                  </div>
                  {/* <div className="group">
                    <FloatingInput label="ZIP CODE" />
                  </div> */}

                  <div className="group sub-btn mb-0 d-flex justify-content-between">
                    <Button
                      type="button"
                      className="col-12 mr-1"
                      onClick={() => closeChangeAddressModal(false)}
                      style={{
                        backgroundColor:
                          outletDetails?.settings?.brand_highlight_color,
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </>
      </PopupModal>
      <PopupModal showDrawer={showTermsPopup} toggleDrawer={closeTermsModal}>
        <>
          <div className="rider-note-modal terms-modal">
            <Terms />
          </div>
          <div className="rider-note-modal-btm">
            <div className="m-3 btm-sec sub-btn m-5">
              <Button
                type="button"
                onClick={() => closeTermsModal(false)}
                style={{
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                  maxWidth: "100%",
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      </PopupModal>

      <form action="//httpbin.org/post" method="POST">
        <section className={`${isLaptopScreen ? " d-flex" : ""}`}>
          {getFormContainer()}
          <div className="outcome">
            <div className="error"></div>
            <div className="success">
              Success! Your Stripe token is <span className="token"></span>
            </div>
          </div>
        </section>
      </form>
      {/* </div> */}

      <div>
        {/* UPDATED QUOTATION MODAL */}
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        {console.log("====================================")}
        {console.log(currencySymbol)}
        {console.log("====================================")}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open && deliveryType.key === "delivery"}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#444343" }}
              >
                Confirm Payment.
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 1, mb: 2, color: "#444343", fontSize: "15px" }}
              >
                Delivery charges has been updated to {currencySymbol.trim()}
                {totalAmount.toFixed(2)}
              </Typography>
              <Stack direction="row" justifyContent="center" sx={{ gap: 1 }}>
                <Button
                  sx={{
                    borderColor: outletDetails?.settings?.brand_main_color,
                    color: outletDetails?.settings?.brand_main_color,
                    backgroundColor: "white",
                    fontWeight: "600",
                    "&:hover": {
                      borderColor: outletDetails?.settings?.brand_main_color,
                    },
                  }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                    color: "white",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor:
                        outletDetails?.settings?.brand_main_color,
                    },
                  }}
                  onClick={(e) => {
                    handleClose();
                    handleSubmit(e);
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </Stack>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

const Payment = ({ isLaptopScreen = false }) => {
  const { outletDetails } = useSelector((state) => state.storeReducer);

  const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_KEY, {
    // stripeAccount: outletDetails?.settings?.stripe_account_id,
  });

  // test key
  // const stripePromise = loadStripe(
  //   "pk_live_uFyANtd6aUd3L00iPTIJ988l00oViywqzJ",
  //   {
  //     stripeAccount: outletDetails?.settings?.stripe_account_id,
  //   }
  // );

  // const options = {anemometer_EE576_V2B2_a
  // passing the client secret obtained from the server
  //   clientSecret: 'pi_3Ko2Y62EMl5K1oJm12lMAWEb_secret_3fAGM6OvIZg2wjntiu3GrEWzs',
  // };
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm isLaptopScreen={isLaptopScreen} />
    </Elements>
  );
};

export default Payment;
